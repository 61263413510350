import { AgSeriesTooltip, AgSeriesTooltipRendererParams } from "ag-charts-community";
import dayjs from "dayjs";
import { compact, get, pick } from "lodash";

import {
  DefaultCashFlowForAddressType,
  DemandConfiguration,
  FullReport,
  OverheadCost,
  Project as TProject,
  ReportConfiguration,
  SomeRequired,
  SystemOfMeasurement,
} from "fond/types";
import { convertFeetToMeters, metersToFeetDisplay } from "fond/utils";
import { formatCurrency } from "fond/utils/currency";
import { createSteppedRange, formatNumber, roundFloat } from "fond/utils/number";
import { Actions, permissionCheck } from "fond/utils/permissions";

import type {
  ReportFormConfiguration,
  ReportFormData,
  ReportWithConfiguration,
  ResourceAllocationFormConfig,
  RevenueFormConfiguration,
} from "./types";

export const transformLinearBudget = (budget?: number, systemOfMeasurement?: SystemOfMeasurement): number | undefined => {
  if (budget === undefined) {
    return budget;
  }
  if (systemOfMeasurement === "imperial") {
    return metersToFeetDisplay(budget);
  } else {
    return roundFloat(budget, 4);
  }
};

export const revenueConfigurationDefaults: Omit<RevenueFormConfiguration, "AddressType" | "Description" | "CashFlow"> = {
  TakeRate: [0, 0.5],
  TakeRateMin: 0,
  TakeRateMax: 50,
  TmnlVal: 0,
};

/**
 * Transform the report form data to a report configuration. This is necessary because the report configuration is stored
 * in a different format than the report form data. The form data has additional fields that are not stored in the report
 *
 * {@link transformReportConfigurationToReportForm} is the inverse of this function.
 * @param report - the report form data to transform
 * @returns
 */
export function transformReportFormToReportSettings(report: ReportFormData): ReportWithConfiguration {
  const { ReportConfiguration: ReportConfig, Name, Description, Project, Version } = report;
  const { Revenue, ResourceAllocation, StartTime, PlanningHorizon, StepFrequency, DiscountRate, CostConfiguration } = ReportConfig;

  // These guards are here for type narrowing purposes.
  // This method is intended to be invoked just before submitting the report form
  // at which point all required fields should be present.
  if (!Project) {
    throw new Error("Project is required");
  }
  if (Project.EntityType === "project" && !Version) {
    throw new Error("Version is required");
  }
  if (PlanningHorizon === undefined) {
    throw new Error("Planning horizon is required");
  }
  if (StepFrequency === undefined) {
    throw new Error("Step frequency is required");
  }
  if (DiscountRate === undefined) {
    throw new Error("Discount rate is required");
  }

  const steps = Number(ReportConfig.PlanningHorizon);
  const TransformedRevenueConfiguration = (Revenue?.RevenueConfigurations as RevenueFormConfiguration[]).map((revenue) => {
    const { TakeRateMin, TakeRateMax, TakeRate, ...rest } = revenue;
    // convert the whole number to a fractional percentage e.g. 50% -> 0.5
    const min = TakeRateMin / 100;
    const max = TakeRateMax / 100;
    return {
      ...rest,
      TakeRate: TakeRate.length !== steps ? createSteppedRange(min, max, steps, 4) : TakeRate,
    };
  });

  const TransformedCostConfiguration =
    CostConfiguration && CostConfiguration!.Costs.length > 0
      ? { ...CostConfiguration, Costs: (CostConfiguration!.Costs as Array<OverheadCost & { ID: string }>).map(({ ID, ...rest }) => rest) }
      : null;

  return {
    Name,
    Description: Description ?? "",
    MultiProjectID: Project.EntityType === "multi_project" ? Project.ID : null,
    VersionID: Version ? Version.ID : null,
    ReportConfiguration: {
      PlanningHorizon,
      StepFrequency,
      DiscountRate: DiscountRate / 100,
      Revenue: { RevenueConfigurations: TransformedRevenueConfiguration },
      ResourceAllocation: {
        ...pick(ResourceAllocation, ["AddressBudget", "MonetaryBudget"]),
        FibreBudget:
          ResourceAllocation?.FibreBudget !== undefined && Project.SystemOfMeasurement === "imperial"
            ? convertFeetToMeters(ResourceAllocation.FibreBudget)
            : ResourceAllocation?.FibreBudget,
        PathBudget:
          ResourceAllocation?.PathBudget !== undefined && Project.SystemOfMeasurement === "imperial"
            ? convertFeetToMeters(ResourceAllocation.PathBudget)
            : ResourceAllocation?.PathBudget,
      },
      StartTime: dayjs(StartTime).format(), // formats date with timezone 2024-01-01T00:00:00+00:00
      CostConfiguration: TransformedCostConfiguration,
    },
  };
}

/**
 * Transform the report configuration of a project to a report form configuration. This is necessary because the
 * report configuration is stored in a different format than the report form configuration. The form configuration
 * has additional fields that are not stored in the report configuration, but necessary for how the form is displayed.
 *
 * {@link transformReportFormToReportSettings} is the inverse of this function.
 * @param reportConfiguration - the report configuration to transform
 * @returns
 */
export function transformReportConfigurationToReportForm(
  reportConfiguration?: ReportConfiguration | null,
  systemOfMeasurement?: SystemOfMeasurement
): ReportFormConfiguration | undefined {
  if (!reportConfiguration) {
    return undefined;
  }

  const { Revenue, ResourceAllocation, DiscountRate, ...PartialReportConfiguration } = reportConfiguration || {};

  // convert the report configuration to a report form configuration
  const revenueFormConfigs: RevenueFormConfiguration[] =
    Revenue?.RevenueConfigurations.map((config) => {
      const takeRates = Array.isArray(config.TakeRate) ? config.TakeRate : [config.TakeRate, config.TakeRate];
      // if there is only a single take rate, we set the min to 0 as the single value is the max
      const minTakeRate = takeRates.length > 1 ? takeRates[0] : 0;

      return {
        ...config,
        TakeRateMin: roundFloat(minTakeRate * 100),
        TakeRateMax: roundFloat((takeRates.at(-1) || 0) * 100),
      };
    }) || [];

  const resourceAllocationValues = pick(ResourceAllocation, ["FibreBudget", "PathBudget", "AddressBudget", "MonetaryBudget"]);
  const resourceAllocationFormConfig: ResourceAllocationFormConfig = {
    ...resourceAllocationValues,
    FibreBudget: transformLinearBudget(ResourceAllocation?.FibreBudget, systemOfMeasurement),
    PathBudget: transformLinearBudget(ResourceAllocation?.PathBudget, systemOfMeasurement),
    ExpansionRateCombinedValue: compact(Object.values(resourceAllocationValues)).join(),
  };
  const reportFormConfig: ReportFormConfiguration = {
    ...PartialReportConfiguration,
    DiscountRate: roundFloat((DiscountRate || 0) * 100),
    StepFrequency: reportConfiguration?.StepFrequency || "quarterly",
    Revenue: {
      RevenueConfigurations: revenueFormConfigs,
    },
    ResourceAllocation: resourceAllocationFormConfig,
    CostConfiguration: reportConfiguration.CostConfiguration ? reportConfiguration.CostConfiguration : { Name: "OverheadCost", Costs: [] },
  };
  return reportFormConfig;
}

/**
 * Transform the demand model configuration of a project to a revenue form configuration
 *
 * @param demandConfigurations - the demand configurations to transform
 * @returns
 */
export const transformDemandToRevenue = (demandConfigurations?: DemandConfiguration[] | null): RevenueFormConfiguration[] | null => {
  if (!demandConfigurations) {
    return null;
  }
  return demandConfigurations.map(({ AddressType, Description }) => ({
    AddressType,
    Description,
    // Get the default cashflow for the address type
    CashFlow: get(DefaultCashFlowForAddressType, AddressType, 100),
    ...revenueConfigurationDefaults,
  }));
};

/**
 * Get a patch object that can be used to update a given report with to its PATCH endpoint
 *
 * @param report - the full report object to create a patch from
 * @returns a partial report object with only the fields that can be updated
 */
export function getReportPatchFromReport(report: FullReport): SomeRequired<FullReport, "ID" | "Name" | "ReportConfiguration"> {
  const { ID, Name, Description, Folder, ReportConfiguration: ReportConfig } = report;
  return {
    ID,
    Name,
    Description,
    // TODO: Uncomment this if/when Version ID is added to the report patch endpoint
    // VersionID: VersionID,
    FolderID: Folder?.ID,
    ReportConfiguration: ReportConfig,
  };
}

/**
 * Merge the current revenue configurations with the new revenue configurations.
 * This is used to ensure that any user-entered data is not lost when the demand model is updated.
 *
 * @param currentRevenueConfigurations
 * @param newRevenueConfigurations
 */
export const mergeRevenueConfigurations = (
  currentRevenueConfigurations: RevenueFormConfiguration[] | null,
  newRevenueConfigurations: RevenueFormConfiguration[] | null
): RevenueFormConfiguration[] | null => {
  if (!currentRevenueConfigurations || !newRevenueConfigurations) {
    return newRevenueConfigurations;
  }
  // we map over the new configuration so that deletions and additions happen naturally
  // then only need to check for existing rows in the current configuration
  return newRevenueConfigurations.map((newRow) => {
    // if there's an existing row we return that, otherwise we return the new row
    // this ensures that any data the user entered is not lost when the demand model is updated
    const currentRow = currentRevenueConfigurations.find((row) => row.AddressType === newRow.AddressType);
    return currentRow ?? newRow;
  });
};

export const seriesTooltip = (keys: string[]): AgSeriesTooltip<AgSeriesTooltipRendererParams> => ({
  renderer: (params) => {
    let value = { ...params.datum };
    keys.forEach((key) => {
      value = value[key];
    });

    return {
      title: params.title,
      content: `${params.datum.Phase}: ${formatNumber(value)}`,
    };
  },
});

export const seriesCurrencyTooltip = (keys: string[]): AgSeriesTooltip<AgSeriesTooltipRendererParams> => ({
  renderer: (params) => {
    let value = { ...params.datum };
    keys.forEach((key) => {
      value = value[key];
    });

    return {
      title: params.title,
      content: `${params.datum.Phase}: ${formatCurrency(value, { notation: "compact" })}`,
    };
  },
});

export const reportWindowTitle = (section: string, reportName?: string): string => `Report ${section} - ${reportName ?? "New Report"}`;

/**
 * Check whether the user has permission to create a report for the given project
 * @param project - the project to check permissions for
 * @returns
 */
export function hasCreateProjectReportPermission(project: TProject): boolean {
  return permissionCheck(project?.Permission.Level, Actions.PROJECT_CREATE_REPORT);
}
