import React, { useState } from "react";
import { GridOptions } from "@ag-grid-community/core";
import { Close, Edit } from "@mui/icons-material";
import { Alert, Box, Button, IconButton, Typography } from "@mui/material";

import { Table, TableBody, TableCell, TableHead, TableRow } from "ui";

import { useGetMultiProjectStatisticsQuery } from "fond/api";
import { MultiProject, MultiProjectArea } from "fond/types";
import { AgGrid, useStackedNavigationContext } from "fond/widgets";

import { rankingColumns } from "./rankingColumns";
import ScoringInputs from "./ScoringInputs";

interface AssessPanelProps {
  multiProject: MultiProject;
}

const AssessPanel: React.FC<AssessPanelProps> = ({ multiProject }: AssessPanelProps) => {
  const { open } = useStackedNavigationContext();
  const [showAlert, setShowAlert] = useState(true);
  const { isLoading, isFetching } = useGetMultiProjectStatisticsQuery(multiProject.ID);

  const gridOptions: GridOptions<MultiProjectArea> = {
    animateRows: true,
    rowGroupPanelShow: "never",
    sideBar: false,
    pagination: false,
    suppressMovableColumns: true,
    domLayout: "normal",
    getRowId: (params) => params.data.ID,
  };

  const handleOnClick = () => {
    open("scoring");
  };

  return (
    <>
      {showAlert && (
        <Alert
          severity="info"
          sx={{ fontSize: 12, mb: 2 }}
          action={
            <IconButton aria-label="close" size="small" onClick={() => setShowAlert(false)} color="inherit">
              <Close fontSize="inherit" />
            </IconButton>
          }
        >
          Use data insights and financial estimators to select target submarkets. The scoring system will help to assess which subareas to proceed
          with in the next steps.
        </Alert>
      )}

      <Box>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
          <Typography variant="h6" sx={{ fontSize: 14, fontWeight: 500 }}>
            Scoring inputs
          </Typography>
          <Button size="small" onClick={handleOnClick}>
            <Edit sx={{ width: 16, height: 16, mr: 0.5 }} />
            Edit
          </Button>
        </Box>
        <Table variant="ag-grid-compact">
          <TableHead>
            <TableRow>
              <TableCell>Category</TableCell>
              <TableCell width={100}>Importance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <ScoringInputs config={multiProject.AssessConfiguration} />
          </TableBody>
        </Table>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography variant="h6" sx={{ fontSize: 14, fontWeight: 500 }}>
          Score board
        </Typography>
        <Box sx={{ "& .ag-center-cols-viewport": { minHeight: "unset !important" } }}>
          <AgGrid
            columnDefs={rankingColumns}
            rowData={multiProject.Areas}
            containerProps={{
              height: 300,
            }}
            size="compact"
            variant="outlined"
            gridOptions={gridOptions}
            loading={isLoading || isFetching}
          />
        </Box>
      </Box>
    </>
  );
};

export default AssessPanel;
