import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  CellEditRequestEvent,
  ColDef,
  EditableCallbackParams,
  GetRowIdParams,
  GridOptions,
  IRowNode,
  RowClassParams,
  SelectionChangedEvent,
} from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { AddBox, Delete, UpdateDisabled } from "@mui/icons-material";
import { Box, Button, IconButton, Theme, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";

import { selectVersionsByProjectId, useGetProjectQuery, useUpdateVersionMutation } from "fond/api";
import { usePermissionCheck } from "fond/hooks/usePermissionCheck";
import { TabHeader } from "fond/layout";
import { setVersion, unselectFeature } from "fond/project/redux";
import DeleteVersionModal from "fond/project/versions/DeleteVersion";
import EditVersionModal from "fond/project/versions/EditVersion";
import NewVersionModal from "fond/project/versions/NewVersion";
import { Store } from "fond/types";
import { useAppDispatch } from "fond/utils/hooks";
import { Actions } from "fond/utils/permissions";
import { AgGrid, NonIdealState } from "fond/widgets";
import { dateValueFormatter } from "fond/widgets/AgGrid";
import DataGridToolbar from "fond/widgets/DataGrid/DataGridToolbar";

const useCustomStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100%",
    "& .ag-center-cols-viewport": {
      overflowX: "hidden",
    },
    display: "flex",
    flexDirection: "column",
  },
  iconGroup: {
    borderRadius: 4,
    "&.active": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
    },
    "&:hover.active": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  current: {
    "&.ag-row": {
      fontWeight: 700,
      background: theme.palette.background.gridRow.active,
      borderColor: "rgba(32, 150, 243, 0.3)",
      color: theme.palette.primary.main,
    },
  },
  row: {
    border: "1px solid transparent",
    "&:hover": {
      fontWeight: 700,
      borderColor: theme.palette.primary.main,
      "& > .ag-cell:first-child": {
        color: theme.palette.primary.main,
      },
    },
    "&.ag-row-selected": {
      fontWeight: 700,
      borderColor: theme.palette.primary.main,
      "& > .ag-cell:first-child": {
        color: theme.palette.primary.main,
      },
    },
  },
  highlight: {
    color: theme.palette.common.white,
  },
  toolbarActionButton: {
    "&.MuiButton-root": {
      fontSize: 10,
      borderRadius: 4,
      marginLeft: 5,
    },
  },
}));

const VersionsList: React.FC = () => {
  const dispatch = useAppDispatch();
  const classes = useCustomStyles();
  const versionId = useSelector((state: Store) => state.project.versionId);
  const projectId = useSelector((state: Store) => state.project.projectId);
  const { data: project } = useGetProjectQuery(projectId);
  const versions = useSelector((state: Store) => selectVersionsByProjectId(state, projectId));
  const [selectedNode, setSelectedNode] = useState<IRowNode | undefined>();
  const gridRef = useRef<AgGridReact>(null);
  const [updateVersion] = useUpdateVersionMutation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const canCreateVersion = usePermissionCheck(Actions.VERSION_ADD, project?.Permission.Level);
  const canRenameVersion = usePermissionCheck(Actions.VERSION_EDIT, project?.Permission.Level);
  const [editVersionModal, showEditVersionModal] = useState(false);
  const [deleteVersionModal, showDeleteVersionModal] = useState(false);
  const [newVersionModal, showNewVersionModal] = useState(false);

  /**
   * Callback function for adding styles to each row within the datagrid.
   * We style the currently selected row.
   */
  const getRowClass = useCallback(
    (params: RowClassParams) => [classes.row, ...(params.data.ID === versionId ? [classes.current, "current-version"] : [])],
    [classes, versionId]
  );

  /**
   * When the versionId changes we need to re-set the getRowClass() within AgGrid.
   * This allows us to correctly highlight the current version being viewed
   */
  useEffect(() => {
    gridRef.current?.api?.setGridOption("getRowClass", getRowClass);
    gridRef.current?.api?.redrawRows();
  }, [getRowClass, versionId]);

  const columns: ColDef[] = [
    {
      field: "Name",
      headerName: "Version",
      flex: 3,
      // We only allow the latest version to be edited
      editable: (params: EditableCallbackParams): boolean => canRenameVersion && params.data.ID === versions?.[0].ID,
      cellEditor: "agTextCellEditor",
    },
    {
      field: "CreatedAt",
      headerName: "Created",
      flex: 2,
      valueFormatter: dateValueFormatter,
    },
  ];

  const gridOptions: GridOptions = {
    defaultColDef: {
      lockPosition: true,
      resizable: false,
      suppressHeaderMenuButton: true,
    },
    sideBar: undefined,
    rowGroupPanelShow: "never",
    rowSelection: {
      mode: "singleRow",
      enableClickSelection: "enableSelection",
      checkboxes: true,
    },
    suppressContextMenu: true,
    stopEditingWhenCellsLoseFocus: true,
    getRowId: (params: GetRowIdParams) => params.data.ID,
    getRowClass,
    onCellEditRequest: (event: CellEditRequestEvent) => {
      // Only save the value if a valid change has been made
      if (event.newValue !== "" && event.newValue !== event.oldValue) {
        updateVersion({ ID: event.data.ID, Name: event.newValue })
          .unwrap()
          .then((version) => {
            enqueueSnackbar(`Version successfully renamed to "${version.Name}"`, {
              key: `version_rename_${versionId}`,
              action: (
                <Button
                  onClick={() => {
                    closeSnackbar(`version_rename_${versionId}`);
                    updateVersion({ ID: event.data.ID, Name: event.oldValue });
                  }}
                >
                  Undo
                </Button>
              ),
            });
          });
      }
    },
  };

  /**
   * Callback function for handling the selection of a row
   */
  const handleOnSelectionChanged = (event: SelectionChangedEvent) => {
    // If the user is attempting to deselect the current version without selecting another then we re-select
    let selection: IRowNode | undefined;
    event.api.forEachNode((node) => {
      if (node.isSelected()) {
        selection = node;
      }
    });
    setSelectedNode(selection);
  };

  /**
   * Callback function to handle the viewing of a different version
   */
  const handleOnView = () => {
    if (selectedNode) {
      dispatch(unselectFeature());
      dispatch(setVersion(selectedNode?.data.ID));
    }
    selectedNode?.setSelected(false);
  };

  /**
   * Callback function to handle the editing of the selected version
   */
  const handleOnEdit = () => {
    showEditVersionModal(true);
  };

  if (!project) {
    return null;
  }

  if (project.MultiProjectArea != null) {
    return <NonIdealState size="small" icon={<UpdateDisabled />} description="City planner projects do not support versions." />;
  }

  return (
    <>
      <Box className={classes.root} data-testid="version-list">
        {canCreateVersion && (
          <TabHeader
            rightAdornments={
              <Tooltip title="Create a new version" PopperProps={{ disablePortal: true }}>
                <IconButton
                  aria-label="info"
                  size="small"
                  onClick={() => showNewVersionModal(true)}
                  className={classes.iconGroup}
                  data-testid="create-version-button"
                  color="primary"
                >
                  <AddBox />
                </IconButton>
              </Tooltip>
            }
          />
        )}
        <Box sx={{ pl: 2, pr: 1 }}>
          <DataGridToolbar
            selected={selectedNode ? 1 : 0}
            title="Select a version to view"
            actions={
              <>
                {(versions?.length || 0) > 1 && (
                  <Tooltip title="Delete current version">
                    <IconButton
                      color="primary"
                      data-testid="delete-row-button"
                      onClick={() => showDeleteVersionModal(true)}
                      sx={{
                        pr: 0,
                      }}
                    >
                      <Delete
                        sx={{
                          height: "24px !important",
                          width: "24px !important",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                )}
                {canRenameVersion && selectedNode?.data.ID === versions?.[0].ID && (
                  <Tooltip title="Edit current version">
                    <span>
                      <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        className={classes.toolbarActionButton}
                        onClick={handleOnEdit}
                        data-testid="version-edit"
                      >
                        EDIT
                      </Button>
                    </span>
                  </Tooltip>
                )}

                <Tooltip title="View this version">
                  <span>
                    <Button
                      size="small"
                      color="primary"
                      variant="contained"
                      className={classes.toolbarActionButton}
                      onClick={handleOnView}
                      data-testid="version-view"
                    >
                      VIEW
                    </Button>
                  </span>
                </Tooltip>
              </>
            }
            size="small"
          />
        </Box>
        <AgGrid
          ref={gridRef}
          columnDefs={columns}
          rowData={versions || []}
          gridOptions={gridOptions}
          onSelectionChanged={handleOnSelectionChanged}
          readOnlyEdit
          size="compact"
        />
      </Box>
      {editVersionModal && selectedNode?.data && <EditVersionModal version={selectedNode?.data} onClose={() => showEditVersionModal(false)} />}
      {deleteVersionModal && <DeleteVersionModal version={selectedNode?.data} onClose={() => showDeleteVersionModal(false)} />}
      {versions && newVersionModal && (
        <NewVersionModal
          projectId={projectId}
          planner={project?.HasCustomLayerConfig === false}
          versionId={versions[0].ID}
          onClose={() => showNewVersionModal(false)}
        />
      )}
    </>
  );
};

export default VersionsList;
