import React from "react";
import { Grid, GridProps, Paper, RegularBreakpoints, Typography } from "@mui/material";

import { GridCardHeader } from "./GridCard.styles";

export type GridCardProps = GridProps & {
  breakpoints: RegularBreakpoints;
  title?: string;
  headerRightElement?: React.ReactNode;
  children?: React.ReactNode | Array<React.ReactNode>;
};

const GridCard: React.FC<GridCardProps> = ({ breakpoints, title, headerRightElement, children, ...rest }) => {
  const showHeader = title || headerRightElement;
  const titleEl = title && (
    <Typography component="h6" fontWeight={700}>
      {title}
    </Typography>
  );
  const headerRightEl = headerRightElement && <div className="grid-card-header-right">{headerRightElement}</div>;

  return (
    <Grid data-testid="summary-card" item {...breakpoints} {...rest}>
      <Paper variant="elevation" elevation={1} square={false} sx={{ display: "flex", flexDirection: "column", height: "100%", overflow: "hidden" }}>
        {showHeader && (
          <GridCardHeader data-testid="summary-card-header">
            {titleEl}
            {headerRightEl}
          </GridCardHeader>
        )}
        {children}
      </Paper>
    </Grid>
  );
};

export default GridCard;
