import { Box, Button, Paper, Typography } from "@mui/material";

import { CardContent, CardPanel } from "./Card.styles";

type IProps = {
  title: string | React.ReactNode;
  description: string | React.ReactNode;
  icon: React.ReactNode;
  buttonText: string;
  onClick: () => void;
};

const Card = ({ title, description, icon, buttonText, onClick }: IProps): JSX.Element => {
  return (
    <CardPanel as={Paper}>
      <CardContent>
        {icon}
        <Box maxWidth="70ch">
          <Typography mb={1.5} variant="h5">
            {title}
          </Typography>
          {description}
        </Box>
      </CardContent>
      <Box display="flex" flex="1" pr={5} justifyContent="center">
        <Button variant="contained" size="large" fullWidth sx={{ maxWidth: 230, whiteSpace: "nowrap" }} onClick={onClick}>
          {buttonText}
        </Button>
      </Box>
    </CardPanel>
  );
};

export default Card;
