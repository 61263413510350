import React from "react";
import { Map as MapIcon } from "@mui/icons-material";
import { Box, Divider, Typography } from "@mui/material";

import MapProvider from "fond/map/MapProvider";
import { ReportMap } from "fond/map/ReportMap";
import mixpanel from "fond/mixpanel";
import { Configuration, FullReport } from "fond/types";
import { formatNumber } from "fond/utils/number";
import { NonIdealState } from "fond/widgets";

import ReportCostMapSlider from "./ReportCostMapSlider";

import { MapContainer } from "../../report.styles";
import { MapCalloutContainer, MapLegendBox } from "../../ReportMapLegend/ReportMapLegend.styles";

interface IProps {
  report: FullReport;
  costMapConfiguration?: Configuration;
  layerVisibilities: Record<string, boolean>;
  binRanges: number[];
  onSliderChange(values: number[]): void;
  passingsCount?: number | null;
  costRange: number[];
}

const ReportCostMapTile: React.FC<IProps> = ({
  report,
  costMapConfiguration,
  layerVisibilities,
  binRanges,
  onSliderChange,
  passingsCount,
  costRange,
}) => (
  <MapContainer height="100%">
    {!report?.CostMap?.CostBinRanges || !report?.CostMap?.BoundingBox || !costMapConfiguration ? (
      <NonIdealState icon={<MapIcon />} description="No map data available." size="small" />
    ) : (
      <>
        <MapProvider mapStyle="monochrome">
          <ReportMap
            onZoomIn={() => mixpanel.track("Report", "Cost", "Map zoom in")}
            onZoomOut={() => mixpanel.track("Report", "Cost", "Map zoom out")}
            layerVisibilities={layerVisibilities}
            reportID={report.ID}
            boundingBox={report.CostMap.BoundingBox}
            systemOfMeasurement={report.SystemOfMeasurement}
            config={costMapConfiguration}
          />
        </MapProvider>
        <MapLegendBox>
          <MapCalloutContainer mb={0.5}>
            <Box py={1.5} px={1}>
              <ReportCostMapSlider costRange={costRange} binRanges={binRanges} onSliderChange={onSliderChange} />
              <Divider sx={{ my: 1 }} />
              <Typography sx={{ pr: 4 }} variant="content" component="p" fontSize={12} fontWeight={400}>
                Service locations
              </Typography>
              <Typography variant="body3" component="span" fontWeight={500} lineHeight={2}>
                {formatNumber(passingsCount)}
              </Typography>
            </Box>
          </MapCalloutContainer>
        </MapLegendBox>
      </>
    )}
  </MapContainer>
);

export default ReportCostMapTile;
