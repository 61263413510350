import React from "react";
import { ColDef, GridReadyEvent, SelectionChangedEvent, SortChangedEvent, ValueGetterParams } from "@ag-grid-community/core";

import { AgGrid, DownloadLinkCellRenderer, fileSizeValueFormatter, MimeTypeCellRenderer, stringComparator } from "../AgGrid";

import { AttachmentFile, Sort } from "./types";

interface IProps {
  files: AttachmentFile[];
  columnDefs?: ColDef[];
  onSelectionChange(files: AttachmentFile[]): void;
  selected: AttachmentFile[];
  pagination?: boolean;
  sort: Sort | null;
  setSort(value: Sort | null): void;
}

const ListView: React.FC<IProps> = ({ files, columnDefs, onSelectionChange, selected, pagination, sort, setSort }) => {
  const selectedFileIds = selected.map(({ ID }) => ID);

  const defaultColumns: ColDef[] = [
    {
      field: "MimeType",
      headerName: "Type",
      minWidth: 80,
      width: 90,
      suppressHeaderMenuButton: true,
      cellRenderer: MimeTypeCellRenderer,
      cellClass: "vertically-align",
      resizable: false,
    },
    {
      field: "Name",
      headerName: "Name",
      minWidth: 100,
      flex: 1,
      suppressHeaderMenuButton: true,
      valueGetter: (params: ValueGetterParams) => `${params.data.Name}.${params.data.Extension}`,
      cellRenderer: DownloadLinkCellRenderer,
      cellRendererParams: {
        hrefConstructor: ({ data }: ValueGetterParams) => data.Urls.Download,
      },
      comparator: stringComparator,
    },
    {
      field: "Size",
      headerName: "Size",
      valueFormatter: fileSizeValueFormatter,
      width: 80,
      type: "rightAligned",
      suppressHeaderMenuButton: true,
    },
  ];

  /**
   * Set the 'selectedItems' state variable from the IDs of the currently selected row items.
   */
  const handleOnSelection = (event: SelectionChangedEvent) => {
    const newSelection: AttachmentFile[] = [];
    event.api.forEachNode((node) => {
      if (node.isSelected()) {
        newSelection.push(node.data);
      }
    });
    onSelectionChange(newSelection);
  };

  const handleOnSortChanged = (event: SortChangedEvent) => {
    if (!event.columns) return;
    const changedColumn = event.columns[0];
    const columnId = changedColumn.getColId() as keyof AttachmentFile;
    const columnSort = changedColumn.getSort();
    if (columnSort == null) {
      setSort(null);
    } else {
      setSort({ colId: columnId, sort: columnSort });
    }
  };

  const handleOnGridReady = (event: GridReadyEvent) => {
    // Handle selection of currently checked files
    event.api.forEachNode((node) => {
      if (selectedFileIds.includes(node.data.ID)) {
        node.setSelected(true);
      }
    });

    // Handle currently applied sorting
    if (sort) {
      event.api.applyColumnState({ state: [sort] });
    }
  };

  return (
    <AgGrid
      columnDefs={columnDefs ?? defaultColumns}
      rowData={files}
      gridOptions={{
        animateRows: true,
        rowGroupPanelShow: "never",
        rowSelection: {
          mode: "multiRow",
          checkboxes: true,
        },
        sideBar: false,
        paginationAutoPageSize: pagination,
        pagination,
        suppressMultiSort: true,
        suppressMovableColumns: true,
      }}
      onSelectionChanged={handleOnSelection}
      onSortChanged={handleOnSortChanged}
      variant="outlined"
      onGridReady={handleOnGridReady}
    />
  );
};

export default ListView;
