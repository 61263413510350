import { ValueFormatterParams } from "@ag-grid-community/core";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(isToday);

/**
 * valueRenderer that formats a date value
 */
const dateValueFormatter = (params: Pick<ValueFormatterParams, "value">): string => {
  const date = dayjs.utc(params.value);
  return date.isToday() ? date.local().format("h:mm A") : date.local().format("DD MMM YYYY h:mm A");
};

export default dateValueFormatter;
