import React, { useRef } from "react";
import { ColDef, GridOptions } from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { SaveAlt } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";

import mixpanel from "fond/mixpanel";
import { AgGrid } from "fond/widgets";
import GridCard from "fond/widgets/GridCard";

interface IProps {
  columns: ColDef[];
  rowData: Record<string, string | number | undefined>[];
  gridOptions: GridOptions;
}

const RevenueData: React.FC<IProps> = ({ columns, rowData, gridOptions }) => {
  const gridRef = useRef<AgGridReact>(null);

  /**
   * Triggers the download of the AgGrid data in excel format
   */
  const onDownloadData = () => {
    mixpanel.track("Report", "Revenue", "Exported via datagrid");
    gridRef.current?.api?.exportDataAsExcel();
  };

  return (
    <GridCard
      breakpoints={{ xs: 12 }}
      title="Data"
      headerRightElement={
        <IconButton size="small" onClick={onDownloadData}>
          <SaveAlt fontSize="inherit" />
        </IconButton>
      }
    >
      <Box p={1}>
        <Box sx={{ overflow: "auto", display: "flex" }}>
          <AgGrid ref={gridRef} columnDefs={columns} rowData={rowData} gridOptions={gridOptions} variant="outlined" />
        </Box>
      </Box>
    </GridCard>
  );
};

export default RevenueData;
