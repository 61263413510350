import { useMemo } from "react";
import { ColDef, GetRowIdParams, GridOptions } from "@ag-grid-community/core";

interface IUseLicenseAssignmentList {
  columns: ColDef[];
  gridOptions: GridOptions;
}

export const useLicenseAssignmentList = (): IUseLicenseAssignmentList => {
  const gridOptions: GridOptions = {
    animateRows: true,
    rowGroupPanelShow: "never",
    sideBar: false,
    pagination: true,
    paginationPageSize: 20,
    suppressMovableColumns: true,
    domLayout: "autoHeight",
    getRowId: (params: GetRowIdParams) => params.data.ID,
  };

  const columns = useMemo(() => {
    const cols: ColDef[] = [
      {
        headerName: "License assigned to",
        field: "User.Email",
        flex: 2,
        sort: "asc",
        menuTabs: ["filterMenuTab"],
        filter: "agTextColumnFilter",
      },
    ];
    return cols;
  }, []);

  return { columns, gridOptions };
};
