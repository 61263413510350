import React, { SetStateAction } from "react";
import { AlignHorizontalCenter, ContactSupport, DensityLarge, HolidayVillage, Info, PowerInput, Undo } from "@mui/icons-material";
import { Box, Button, Checkbox, CircularProgress, Divider, FormControlLabel, Tooltip, Typography } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { useGetAccountModulesQuery } from "fond/api";
import { AsyncOperationState } from "fond/async/redux";
import { LayerId, LayerIds } from "fond/layers";
import * as turf from "fond/turf";
import { Project, ProjectState, Version } from "fond/types";
import { sqMetersToSqKilometers, sqMetresToSqMiles } from "fond/utils/area";
import { accountModuleCheck, Actions } from "fond/utils/permissions";

import { getArchitectureAddressTypes, getUnknownAddressTypes, UnknownAddressTypesAlert } from "../../addressTypes";
import { MAX_AREA_IN_SQ_KILOMETERS, MAX_AREA_IN_SQ_MILES, MAX_NUM_ADDRESSES, PolygonStatus } from "../../polygon";
import { PolygonState } from "../../polygon/redux";

import { Alert, DefaultAlert, LinkButton, SelectedAreaDetails } from "../InputDataPanel.styles";

interface IProps extends Omit<ProjectState["polygon"], "isOpen" | "errorMessage"> {
  project: Project;
  version?: Version;
  togglePolygonLayer: (layerId: LayerId) => void;
  convertToDualSided: boolean;
  setConvertToDualSided: React.Dispatch<SetStateAction<boolean>>;
  setIsConfirmingReset: (value: boolean) => void;
  onImportButtonClick: () => void;
  handleBack(): void;
  onReset: () => void;
  onRetryDownloadClick: () => void;
  isIncludeUgPathDisabled?: boolean;
}

const DrawPolygon: React.FC<IProps> = ({
  polygonState,
  downloadState,
  selectedLayers,
  selectedData,
  incompleteData,
  isTooBig,
  selectedArea,
  status,
  project,
  version,
  togglePolygonLayer,
  convertToDualSided,
  setConvertToDualSided,
  setIsConfirmingReset,
  onImportButtonClick,
  handleBack,
  onReset,
  onRetryDownloadClick,
  isIncludeUgPathDisabled = false,
}) => {
  const selectingAddresses = selectedLayers[LayerIds.inAddress];
  const selectingUndergroundPath = selectedLayers[LayerIds.inStreet];

  const parcelCount = selectedData[LayerIds.inParcel].features.length;
  const ugLength = selectedData[LayerIds.inStreet].features.length;
  const spanLength = selectedData[LayerIds.inSpan].features.length;
  const polesCount = selectedData[LayerIds.inPole].features.length;
  const { data: accountModules } = useGetAccountModulesQuery(project?.Account.ID ?? skipToken);
  const canSelectDualSided = accountModuleCheck(accountModules, Actions.SELECT_UNDERGROUND_PATH_DUAL_SIDED);

  // This component shows different inputs depending on whether we're doing an underground or aerial import.
  const aerial = polygonState === PolygonState.complete && (selectedLayers[LayerIds.inSpan] || selectedLayers[LayerIds.inPole]);
  const hasPartiallyIncompleteData = aerial
    ? incompleteData[LayerIds.inSpan] || incompleteData[LayerIds.inPole]
    : incompleteData[LayerIds.inAddress] || incompleteData[LayerIds.inStreet];
  const hasTotallyIncompleteData = aerial
    ? incompleteData[LayerIds.inSpan] && incompleteData[LayerIds.inPole]
    : incompleteData[LayerIds.inAddress] && incompleteData[LayerIds.inStreet];

  const architectureAddressTypes = version?.Architecture ? getArchitectureAddressTypes({ architecture: version.Architecture }) : null;

  let unknownAddressTypes: string[] = [];
  if (
    architectureAddressTypes !== null &&
    polygonState === PolygonState.complete &&
    selectedLayers[LayerIds.inParcel] &&
    !incompleteData[LayerIds.inParcel]
  ) {
    unknownAddressTypes = getUnknownAddressTypes({
      featureCollection: selectedData[LayerIds.inParcel],
      knownAddressTypes: architectureAddressTypes,
    }).unknownAddressTypes;
  }

  /**
   * ----------- DRAWING STATE ------------
   */
  if (polygonState === PolygonState.nullDrawing) {
    return (
      <Alert icon={<Info fontSize="inherit" color="primary" />} severity="info">
        Navigate to an area, or use the search bar on the map.
      </Alert>
    );
  }

  if (polygonState === PolygonState.partial) {
    return (
      <Alert severity="info" icon={<Info fontSize="inherit" color="primary" />}>
        Double-click when you draw the final vertex to complete the polygon.
      </Alert>
    );
  }
  /**
   * ----------- DRAWING STATE ------------
   */

  /**
   * ----------- ERROR ------------
   */
  if (isTooBig) {
    return (
      <Alert severity="warning">
        <Typography variant="body3" component="p">
          {project.SystemOfMeasurement === "imperial"
            ? `You have selected an area of ${Math.round(sqMetresToSqMiles(turf.area(selectedArea))).toLocaleString()} square miles. `
            : `You have selected an area of ${Math.round(sqMetersToSqKilometers(turf.area(selectedArea))).toLocaleString()} square miles. `}
          <strong>
            The maximum size allowed is{" "}
            {project.SystemOfMeasurement === "imperial"
              ? `${MAX_AREA_IN_SQ_MILES.toLocaleString()} square miles.`
              : `${MAX_AREA_IN_SQ_KILOMETERS.toLocaleString()} square kilometers.`}
          </strong>
        </Typography>
        <LinkButton onClick={onReset}>Reset</LinkButton>
      </Alert>
    );
  }
  if (status === PolygonStatus.QueryTooLarge) {
    return (
      <Alert severity="warning" data-testid="query-too-large-error-message">
        <Typography variant="body3" component="p">
          The selected area is too large. Please select a smaller area.
        </Typography>
        <Typography variant="body3" data-testid="query-too-large-error-message" component="p">
          The number of features in the query area exceeds the limit of {MAX_NUM_ADDRESSES.toLocaleString()}.
        </Typography>
        <LinkButton onClick={onReset}>Reset</LinkButton>
      </Alert>
    );
  }
  if (status === PolygonStatus.InvalidArea) {
    return (
      <Alert severity="warning">
        <Typography variant="body3" component="p">
          Invalid area selected. It appears the area overlaps itself. Please reset and redraw the area.
        </Typography>
        <LinkButton onClick={onReset}>Reset</LinkButton>
      </Alert>
    );
  }
  if (downloadState === AsyncOperationState.failure) {
    return (
      <Alert severity="error">
        <Typography variant="body3" component="p">
          There was a problem downloading the required data.
        </Typography>
        <LinkButton onClick={onRetryDownloadClick}>Retry</LinkButton>
      </Alert>
    );
  }
  /**
   * ----------- ERROR ------------
   */

  if (downloadState === AsyncOperationState.executing) {
    return (
      <Alert severity="info" icon={<CircularProgress size={16} />}>
        Downloading data for the selected area...
      </Alert>
    );
  }

  /**
   * ----------- DRAWING/DOWNLOAD COMPLETE ------------
   */
  if (polygonState === PolygonState.complete && downloadState === AsyncOperationState.success) {
    if (hasPartiallyIncompleteData) {
      return (
        <Alert severity="warning">
          <Typography variant="body3" component="p" mb={1}>
            Sorry, it looks like FOND does not currently have complete data available for the area you have selected. We are increasing our coverage
            regularly so it may be available in the future.
          </Typography>
          <LinkButton onClick={onReset}>Reset</LinkButton>
        </Alert>
      );
    } else {
      return (
        <Box px={1} pb={1.5} data-testid="polygon-select-completed">
          {unknownAddressTypes.length > 0 && <UnknownAddressTypesAlert unknownAddressTypes={unknownAddressTypes} />}
          {selectingAddresses && (
            <>
              <Box data-testid="selected-addresses-info" mb={2}>
                <Typography fontWeight="500">Addresses</Typography>
                <Box pb={2}>
                  <SelectedAreaDetails>
                    <HolidayVillage />
                    <Typography data-testid="num-parcels" variant="body3" lineHeight="1.8" fontWeight="500">
                      {`${parcelCount.toLocaleString()} ${parcelCount === 1 ? "Parcel" : "Parcels"}`}
                    </Typography>
                  </SelectedAreaDetails>
                  <Typography variant="caption" letterSpacing="0.15px" color={(theme) => theme.palette.biarri.secondary.grey} component="p">
                    FOND will convert parcels to address points.
                  </Typography>
                </Box>
                <DefaultAlert
                  sx={{ mb: 1.5 }}
                  severity="info"
                  icon={<ContactSupport sx={{ fontSize: "inherit", color: (theme) => theme.palette.biarri.secondary.activeGrey }} />}
                >
                  <Box>
                    <Typography variant="h7" component="p" letterSpacing={0.15}>
                      You can control how each address point is served after the upload is complete.
                    </Typography>
                    <Button
                      variant="text"
                      sx={{ padding: 0, fontSize: 12, mt: 1 }}
                      component="a"
                      href="https://fondhelp.biarrinetworks.com/how-to-control-fiber-allocation-in-fond"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Learn it here
                    </Button>
                  </Box>
                </DefaultAlert>
              </Box>
              <Divider />
              <Tooltip
                title={
                  (parcelCount === 0 || isIncludeUgPathDisabled) && "Area select can only be used for either Underground path or Aerial and not both."
                }
              >
                <Box data-testid="include-ug-checkbox">
                  <FormControlLabel
                    disabled={parcelCount === 0 || isIncludeUgPathDisabled}
                    control={<Checkbox checked={selectedLayers[LayerIds.inStreet]} onChange={() => togglePolygonLayer(LayerIds.inStreet)} />}
                    label={<Typography variant="body3">Include Underground Path</Typography>}
                  />
                </Box>
              </Tooltip>
            </>
          )}
          {selectingUndergroundPath && (
            <Box pb={2} data-testid="selected-underground-path-info">
              <Typography fontWeight="500">Underground path</Typography>
              <SelectedAreaDetails>
                <PowerInput />
                <Typography data-testid="num-parcels" variant="body3" lineHeight="1.8" fontWeight="500">
                  {`${ugLength.toLocaleString()} ${ugLength === 1 ? "Path" : "Paths"}`}
                </Typography>
              </SelectedAreaDetails>

              {canSelectDualSided && (
                <>
                  <Typography fontWeight="500">Advanced control</Typography>
                  <FormControlLabel
                    control={<Checkbox checked={convertToDualSided} onChange={() => setConvertToDualSided((prev) => !prev)} />}
                    label={<Typography variant="body3">Convert to dual-sided</Typography>}
                  />
                  <Typography variant="caption" color={(theme) => theme.palette.biarri.secondary.grey} component="p" letterSpacing={0.53}>
                    FOND will approximate underground with streets and can convert streets centerlines to a dual-sided network.
                  </Typography>
                </>
              )}
            </Box>
          )}
          {aerial && (
            <Box pb={2} data-testid="selected-spanPole-info">
              <Typography fontWeight="500">Aerial spans and poles</Typography>
              <SelectedAreaDetails>
                <DensityLarge sx={{ transform: "rotate(90deg)" }} />
                <Typography data-testid="num-spans" variant="body3" lineHeight="1.8" fontWeight="500">
                  {`${spanLength.toLocaleString()} ${spanLength === 1 ? "Span" : "Spans"}`}
                </Typography>
              </SelectedAreaDetails>
              <SelectedAreaDetails>
                <AlignHorizontalCenter />
                <Typography data-testid="num-poles" variant="body3" lineHeight="1.8" fontWeight="500">
                  {`${polesCount.toLocaleString()} ${polesCount === 1 ? "Pole" : "Poles"}`}
                </Typography>
              </SelectedAreaDetails>
              <Divider sx={{ my: 2 }} />
              <Typography variant="caption" color={(theme) => theme.palette.biarri.secondary.grey} component="p" letterSpacing={0.53}>
                FOND will approximate aerial with street centerlines.
              </Typography>
            </Box>
          )}
          <Divider />
          <Button
            fullWidth
            sx={{ my: 0.5, justifyContent: "left" }}
            startIcon={<Undo sx={{ height: 20 }} />}
            onClick={() => setIsConfirmingReset(true)}
            data-testid="reset-button"
          >
            <Typography color={(theme) => theme.palette.common.black} fontWeight={500} fontSize={13}>
              Reset
            </Typography>
          </Button>
          <Divider />
          <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
            <Button color="primary" size="small" onClick={handleBack} data-testid="cancel-button">
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              disabled={hasTotallyIncompleteData}
              onClick={onImportButtonClick}
              sx={{ ml: 1, px: 2 }}
              data-testid="import-button"
            >
              Import
            </Button>
          </Box>
        </Box>
      );
    }
  }
  /**
   * ----------- DRAWING/DOWNLOAD COMPLETE ------------
   */

  return null;
};

export default DrawPolygon;
