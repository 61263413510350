import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { DriveFileMoveOutlined as FolderIcon } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

import { selectCurrentAccount } from "fond/api";
import { clearSharedAccount, updateFilter, updateSharedAccount } from "fond/projects/redux";
import { EntityLabel, EntityType, Folder, MultiProject, Project, Report } from "fond/types";
import { useAppDispatch } from "fond/utils/hooks";

import { IconRouterLink } from "./topbar.styles";

interface IProps {
  entity: Project | Report | MultiProject | Folder;
}

const getFolderId = (entity: IProps["entity"]): string | null | undefined => {
  switch (entity.EntityType) {
    case "project":
      return entity.FolderID;
    case "folder":
      // the "folder" for a folder is the parent folder.
      return entity.ParentID ?? entity.Parent?.ID;
    default:
      return entity.Folder?.ID;
  }
};

const ViewEntityFolderButton: React.FC<IProps> = ({ entity }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentAccount = useSelector(selectCurrentAccount);

  const entityType = entity.EntityType;
  const folderId = getFolderId(entity);
  const entityAccountId = entity.Account?.ID;
  const entityInCurrentAccount = currentAccount?.ID === entityAccountId;

  const getPath = () => {
    if (folderId) {
      if (entityType === "multi_project" || (entityType === "project" && entity.MultiProjectArea !== null)) {
        return `/cities/${folderId}`;
      } else {
        return `/folders/${folderId}`;
      }
    } else {
      const path = entityInCurrentAccount ? "/workspace/" : "/shared/";
      return path;
    }
  };

  const clickHandler = (e: React.MouseEvent) => {
    // If the entity is in the current account, clear the shared account ID
    if (entityInCurrentAccount) {
      dispatch(updateFilter("workspace"));
      dispatch(clearSharedAccount);
    } else {
      dispatch(updateFilter("shared"));
      dispatch(updateSharedAccount(entityAccountId));
    }
  };

  const entityTypeForLabel: EntityType = entityType === "folder" && entity.MultiProject ? "multi_project" : entityType;

  return (
    <Tooltip title={`Go to this ${EntityLabel[entityTypeForLabel]}'s folder`}>
      <IconRouterLink to={getPath()} onClick={clickHandler} data-testid={`view-${entityType}-folder-btn`}>
        <FolderIcon fontSize="inherit" />
      </IconRouterLink>
    </Tooltip>
  );
};

export default ViewEntityFolderButton;
