import { ColDef, GridOptions, IsGroupOpenByDefaultParams } from "@ag-grid-community/core";

import { CashflowDataItem, CostDataItem, HhcDataItem, HhpDataItem, RevenueDataItem, Schedules } from "fond/types";

export const defaultColDef: ColDef = {
  flex: 1,
  minWidth: 125,
  resizable: false,
  rowGroup: false,
  sortable: false,
  suppressHeaderMenuButton: true,
  suppressMovable: true,
};

type BaseOptions = {
  label: string;
  gridOptions: GridOptions;
};

type TVisualizationTypes = {
  [Schedules.Cashflow]: BaseOptions & {
    dataPoints: Array<{ name: string; key: keyof CashflowDataItem }>;
  };
  [Schedules.Revenue]: BaseOptions & {
    dataPoints: Array<{ name: string; key: keyof RevenueDataItem }>;
  };
  [Schedules.Cost]: BaseOptions & {
    dataPoints: Array<{ name: string; key: keyof CostDataItem }>;
  };
  [Schedules.Hhc]: BaseOptions & {
    dataPoints: Array<{ name: string; key: keyof HhcDataItem }>;
  };
  [Schedules.Hhp]: BaseOptions & {
    dataPoints: Array<{ name: string; key: keyof HhpDataItem }>;
  };
};
/** AgGrid options that support grouping by Address Type */
const groupedGridOptions = (headerName: string): GridOptions => ({
  autoGroupColumnDef: {
    field: "Key",
    headerName,
    pinned: "left",
    cellRendererParams: {
      suppressCount: true,
    },
    cellStyle: (_) => ({
      fontWeight: 500,
      fontSize: 13,
    }),
    cellClass: "ag-grid-no-indent",
    width: 250,
    minWidth: 250,
  },
  isGroupOpenByDefault: (params: IsGroupOpenByDefaultParams) => params.key === "All",
  defaultColDef,
  domLayout: "autoHeight",
  sideBar: false,
  rowGroupPanelShow: "never",
  suppressAggFuncInHeader: true,
  getContextMenuItems: () => [],
});

export const VisualizationTypes: TVisualizationTypes = {
  Cashflow: {
    label: "Overall position",
    dataPoints: [
      { name: "Net revenue", key: "NetRevenue" },
      { name: "Net cost", key: "NetCost" },
      { name: "Net position", key: "NetPosition" },
      { name: "Cumulative net position", key: "CumulativeNetPosition" },
    ],
    gridOptions: {
      defaultColDef,
      domLayout: "autoHeight",
      sideBar: false,
      rowGroupPanelShow: "never",
      suppressAggFuncInHeader: true,
      getContextMenuItems: () => [],
    },
  },
  Revenue: {
    label: "Revenue projection",
    dataPoints: [
      { name: "Subscription revenue", key: "SubscriptionRevenue" },
      { name: "Net revenue", key: "NetRevenue" },
      { name: "Cumulative net revenue", key: "CumulativeNetRevenue" },
    ],
    gridOptions: groupedGridOptions("Address Type"),
  },
  Cost: {
    label: "Cost projection",
    dataPoints: [
      { name: "Construction cost", key: "ConstructionCost" },
      { name: "Net cost", key: "NetCost" },
      { name: "Cumulative net cost", key: "CumulativeNetCost" },
    ],
    gridOptions: groupedGridOptions("Tag"),
  },
  Hhc: {
    label: "Projected connections",
    dataPoints: [
      { name: "New connections", key: "NewConnections" },
      { name: "Cumulative total", key: "CumulativeTotal" },
      { name: "Cumulative percent", key: "CumulativePercent" },
    ],
    gridOptions: groupedGridOptions("Address Type"),
  },
  Hhp: {
    label: "Projected passings",
    dataPoints: [
      { name: "New passings", key: "NewPassings" },
      { name: "Cumulative total", key: "CumulativeTotal" },
      { name: "Cumulative percent", key: "CumulativePercent" },
    ],
    gridOptions: groupedGridOptions("Address Type"),
  },
};
