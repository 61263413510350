import { FeatureCollection, MultiPolygon } from "geojson";

import { AccountBase, PermissionLevel, SolveError, WorkflowQuality } from "fond/types";

export type MultiProjectAreaImportStatus = {
  State: "PENDING" | "IN_PROGRESS" | "COMPLETE" | "ERROR";
  CurrentStepDescription: string;
  CurrentStepIndex: number;
  Progress: number | null;
};

/**
 * A MultiProjectArea as referenced from a project.
 */
export interface ProjectMultiProjectArea {
  ID: string;
  Name: string;
  MultiProject: { ID: string; Name: string };
  ImportStatus: MultiProjectAreaImportStatus | null;
  /**
   * The Boundary will be populated for a 'full' Project object, and missing from the 'list' Project object.
   */
  Boundary?: MultiPolygon;
}

export interface Project {
  /**
   * The account the project was created within
   */
  Account: AccountBase;
  BoundingBox: any;
  /**
   * Timestamp (UTC) of the time the project was created
   */
  Created: string;
  /**
   * Username of the user that created the project
   */
  Creator: string;
  /**
   * Flag indicating if the project can be deleted
   */
  Deletable: boolean;
  /**
   * Flag indicating that the project has been deleted.
   */
  Deleted: boolean;
  /**
   * The type of entity.  This is usefully when a folder contains
   * content that is a combination of entity types (Projects and Folders)
   */
  EntityType: "project";
  /**
   * Unique identifier for the project
   */
  ID: string;
  /**
   * The username of the last user to edit the project
   */
  LastModified: string;
  /**
   * Timestamp (UTC) of the last time the project was modified
   */
  LastModifiedBy: string;
  /**
   * The Folder entity that the project is contained within
   */
  FolderID?: string | null;
  OutputSchema?: string;
  /**
   * The read/write permission of the logged in user in relation to the project
   */
  Permission: {
    ID: string;
    Identity: string;
    IdentityType: "account" | "user";
    Inherited: boolean;
    Level: PermissionLevel;
    Resource: string;
    ResourceType: "project" | "folder";
  };
  /**
   * The name of the project
   */
  ProjectName: string;
  /**
   * The System of measurement (feet | meters) used by the project
   */
  SystemOfMeasurement: "imperial" | "metric";
  /**
   * Legacy flag indicating that the project is using BlueSteel version 11
   */
  UsesBlueSteelV11?: boolean;
  /**
   * Flag indicating that the project is using custom layers that have been imported into FOND
   */
  HasCustomLayerConfig?: boolean;
  /**
   * Flag indicating the current position of mapbox popup if it is dragged
   */
  draggedPopupPosition?: mapboxgl.LngLatLike;
  WriteLocked?: string;
  /**
   * The MultiProjectArea associated to this project, if any.
   */
  MultiProjectArea: ProjectMultiProjectArea | null;
  SubType: ProjectType;
}

export type ProjectPreview = Pick<
  Project,
  | "Account"
  | "Creator"
  | "Deletable"
  | "EntityType"
  | "ID"
  | "LastModified"
  | "LastModifiedBy"
  | "Permission"
  | "ProjectName"
  | "FolderID"
  | "UsesBlueSteelV11"
  | "HasCustomLayerConfig"
  | "SubType"
>;

export type ProjectsView = "workspace" | "shared" | "recent" | "starred";

export type Status = "Idle" | "Running" | "Complete" | "Cancelled" | "Cancelling" | "Terminated" | "NotReady";

export type WorkflowStep = {
  ID: "Preprocessor" | "Tier_1_Solve" | "Tier_2_Solve" | "Tier_3_Solve" | "Splicing" | "BOM" | "FlexNapPostprocessor" | "BsOutput" | "CostToServe";
  Status: Exclude<Status, "NotReady">;
  JobID: string;
  ToolName: string;
  ToolVersion: string;
};

export type ProjectStatus = {
  BomHasChanged: boolean;
  CancelledBy?: string;
  Error?: SolveError[];
  HasSolution?: boolean | null;
  GoalState?: string;
  // Inputfiles and Workflow need to be outlined further
  InputFiles: any;
  IsDirty: boolean;
  /**
   * Username of the user that initiated the job
   */
  StartedBy?: string;
  Status: Status;
  /**
   * Current status of the job
   */
  StatusCode: string;
  /**
   * Workflow of the jobs to be ran (in order) & their current status
   */
  Workflow: WorkflowStep[] | null;
  /**
   * The quality of the current workflow. Is updated when a workflow is started.
   */
  WorkflowQuality: WorkflowQuality | null;
};

export type FeaturePropertiesProps = {
  /**
   * Input parameters to update feature properties
   */
  featureId: string;
  properties: any;
};

export type UploadType = {
  status: string;
  percent: number;
  uploadComplete: boolean;
  failureMessage: string;
};

export type geoSpatialConvertProps = {
  selectedFiles: any;
  xhrEvents: any;
};

export type ProjectFiles = { [path: string]: FeatureCollection };

export type SystemOfMeasurement = "metric" | "imperial";

export type ProjectType = "collaboration" | "planner" | "design";

export const projectTypeLabelMapping: Record<ProjectType, string> = {
  planner: "Planner",
  collaboration: "Collaboration",
  design: "Design",
};
