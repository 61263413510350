import React, { useCallback, useMemo, useRef } from "react";
import { SaveAlt } from "@mui/icons-material";
import { Box, IconButton, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { AgCartesianSeriesOptions, AgChartOptions, AgCharts } from "ag-charts-community";
import { AgChartsReact } from "ag-charts-react";
import chroma from "chroma-js";
import dayjs from "dayjs";
import { groupBy } from "lodash";

import mixpanel from "fond/mixpanel";
import { Schedules } from "fond/types";
import { replaceAllSpecialCharacters } from "fond/utils/strings";
import { ZoomableGridCard } from "fond/widgets/ZoomableGridCard";

import { seriesCurrencyTooltip, seriesTooltip } from "../util";

import { VisualizationTypes } from "./VisualizationTypes";

const colors: string[] = [
  "#00B0FF",
  "#FDD835",
  "#F06292",
  "#00C853",
  "#FFA000",
  "#E53935",
  "#FFCCBC",
  "#8BC34A",
  "#BA68C8",
  "#80DEEA",
  "#26A69A",
  "#0D47A1",
  "#8C9EFF",
  "#546E7A",
];
interface IProps {
  selected: string;
  data: any[];
  onVisualizationTypeChange(value: Schedules): void;
}

const groupByAddressType = (data: any[]): any[] => {
  const grouped = groupBy(data, "Phase");
  return Object.keys(grouped).map((key) => {
    const row = grouped[key];
    return row.reduce(
      (acc, { AddressType, Phase, ...rest }) => {
        acc[sanitize(AddressType)] = { ...rest };
        return acc;
      },
      { Phase: key }
    );
  });
};

const groupByTag = (data: any[]): any[] => {
  const grouped = groupBy(data, "Phase");
  return Object.keys(grouped).map((key) => {
    const row = grouped[key];
    // Tags can contain special symbols(e.g, &, .) that the AG grid chart couldn't handle.
    // To avoid that, when plotting, we simplify the tags to "Tag_1", "Tag_2", etc.
    return row.reduce(
      (acc, { Tag, Phase, ...rest }, index) => {
        acc[`Tag_${index}`] = { ...rest };
        return acc;
      },
      { Phase: key }
    );
  });
};

// Replaces any special characters within the addressType value with the equivalent hex code.
// Special characters are not allowed within strings being used for object dot notation. We replace
// with hexcode values in an attempt to avoid losing uniqueness (e.g. SFU-High vs SFU High).
const sanitize = (addressType: string) => replaceAllSpecialCharacters(addressType, (val: string) => val.charCodeAt(0).toString(16));

const removeEntryAll = (list: string[]) => list.filter((item) => item !== "All");

const formatDataPhase = (data: any[]) =>
  data.map(({ Phase, ...rest }) => {
    if (Phase == null) return rest;

    const isValidDate = dayjs(Phase).isValid();
    const formattedPhase = isValidDate ? dayjs(Phase).format("YYYY-MM") : Phase;
    return { Phase: formattedPhase, ...rest };
  });

const ReportRevenueChart: React.FC<IProps> = ({ selected, data, onVisualizationTypeChange }) => {
  const chartRef = useRef<AgChartsReact | null>(null);
  const previousChartRef = useRef<AgChartsReact | null>(null);

  const chartData = useMemo(() => {
    const formattedData = formatDataPhase(data);
    if (selected === "Cashflow") return formattedData;
    if (selected === "Cost") return groupByTag(formattedData);
    return groupByAddressType(formattedData);
  }, [data, selected]);

  const chartSeries = useMemo((): AgCartesianSeriesOptions[] => {
    const addressTypes: string[] = removeEntryAll([...new Set<string>(data?.map(({ AddressType }) => AddressType))]);
    const colorRange = addressTypes.length > colors.length ? chroma.scale(colors).colors(addressTypes.length) : colors;

    switch (selected) {
      case "Hhp": {
        return addressTypes.map((addressType, index) => ({
          type: "area",
          xKey: "Phase",
          yKey: `${sanitize(addressType)}.CumulativeTotal`,
          yName: addressType,
          tooltip: seriesTooltip([sanitize(addressType), "CumulativeTotal"]),
          stacked: true,
          fill: colorRange[index],
        }));
      }
      case "Hhc": {
        return addressTypes.map((addressType, index) => ({
          type: "area",
          xKey: "Phase",
          yKey: `${sanitize(addressType)}.CumulativeTotal`,
          yName: addressType,
          tooltip: seriesTooltip([sanitize(addressType), "CumulativeTotal"]),
          stacked: true,
          fill: colorRange[index],
        }));
      }
      case "Revenue": {
        return addressTypes.map((addressType, index) => ({
          type: "area",
          xKey: "Phase",
          yKey: `${sanitize(addressType)}.CumulativeNetRevenue`,
          yName: addressType,
          tooltip: seriesCurrencyTooltip([sanitize(addressType), "CumulativeNetRevenue"]),
          stacked: true,
          fill: colorRange[index],
        }));
      }
      case "Cashflow": {
        return [
          {
            type: "line",
            xKey: "Phase",
            yKey: "CumulativeNetPosition",
            yName: "Cumulative Net Position",
            tooltip: seriesCurrencyTooltip(["CumulativeNetPosition"]),
          },
        ];
      }
      case "Cost": {
        return removeEntryAll([...new Set<string>(data?.map(({ Tag }) => Tag))]).map((tag, index) => ({
          type: "area",
          xKey: "Phase",
          yKey: `Tag_${index}.CumulativeNetCost`,
          yName: tag,
          tooltip: seriesCurrencyTooltip([`Tag_${index}`, "CumulativeNetCost"]),
          stacked: true,
          fill: colorRange[index],
        }));
      }
      default: {
        return [];
      }
    }
  }, [data, selected]);

  const yAxisTitle = useMemo((): string => {
    switch (selected) {
      case "Hhp":
        return "Cumulative total";
      case "Hhc":
        return "Cumulative total";
      case "Revenue":
        return "Cumulative net revenue";
      case "Cost":
        return "Cumulative net cost";
      case "Cashflow":
        return "Cumulative net position";
      default:
        return "";
    }
  }, [selected]);

  const downloadChart = () => {
    mixpanel.track("Report", "Revenue", "Downloaded revenue chart");
    AgCharts.download(chartRef.current!.chart);
  };

  const handleVisualizationTypeChange = (event: SelectChangeEvent) => {
    onVisualizationTypeChange(event.target.value as Schedules);
  };

  const onOpenZoom = () => {
    // store the current chart
    previousChartRef.current = chartRef.current;
  };

  const onCloseZoom = useCallback(() => {
    // restore the previous chart
    chartRef.current = previousChartRef.current;
  }, [previousChartRef]);

  const chartOptions: AgChartOptions = {
    data: chartData,
    series: chartSeries,
    autoSize: true,
    axes: [
      {
        type: "category",
        position: "bottom",
        label: {
          fontSize: 10,
        },
      },
      {
        type: "number",
        position: "left",
        title: {
          text: yAxisTitle,
          fontSize: 12,
        },
        label: {
          format: "~s",
          formatter: (params) => params.formatter!(params.value).replace("G", "B"),
        },
      },
    ],
  };

  return (
    <ZoomableGridCard
      breakpoints={{ lg: 6, xs: 12 }}
      title="Data visualization"
      headerRightElement={
        <>
          <Box width={200}>
            <Select fullWidth value={selected} size="small" onChange={handleVisualizationTypeChange}>
              {Object.entries(VisualizationTypes).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <IconButton size="small" onClick={downloadChart}>
            <SaveAlt fontSize="inherit" />
          </IconButton>
        </>
      }
      onOpen={onOpenZoom}
      onClose={onCloseZoom}
    >
      <Box flex={1} px={2.5} py={3}>
        <AgChartsReact ref={chartRef} options={chartOptions} />
      </Box>
    </ZoomableGridCard>
  );
};

export default ReportRevenueChart;
