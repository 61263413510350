import React, { useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";

const useCustomStyles = makeStyles<Theme, { size: "small" | "normal" }>((theme: Theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: theme.spacing(1), // Reduced padding for smaller height
  },
  title: {
    paddingTop: theme.spacing(1),
    fontSize: ({ size }) => (size === "small" ? 12 : theme.typography.h4.fontSize),
    paddingLeft: theme.spacing(1),
  },
  description: {
    width: "100%",
    paddingTop: theme.spacing(1),
    fontSize: ({ size }) => (size === "small" ? 12 : theme.typography.subtitle1.fontSize),
    paddingLeft: theme.spacing(1),
  },
  icon: {
    fontSize: ({ size }) => (size === "small" ? "2em" : "4em"), // Reduced icon size for smaller height
  },
  defaultIconColor: {
    color: theme.palette.grey[300],
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center", // Center content vertically in row layout
  },
}));

interface IProps {
  "data-testid"?: string;
  /**
   * The main image displayed, a material-ui icon.
   */
  icon?: React.ReactElement;
  /**
   * The main message of the page.
   */
  title?: string;
  /**
   * The description on how to move forward.
   */
  description: string | React.ReactNode;
  /**
   * Optional actions, such as buttons.
   */
  action?: React.ReactNode;
  /**
   * Optional information.
   */
  children?: React.ReactNode;
  /**
   * The size of the content
   */
  size?: "small" | "normal";
}

const NonIdealState: React.FC<IProps> = ({ "data-testid": dataTestId, icon, title, description, action, children, size = "normal" }: IProps) => {
  const classes = useCustomStyles({ size });
  const rootRef = useRef<HTMLDivElement>(null);
  const [isSmallHeight, setIsSmallHeight] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (rootRef.current) {
        setIsSmallHeight(rootRef.current.clientHeight < 200);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box ref={rootRef} className={classNames(classes.root, { [classes.rootRow]: isSmallHeight })} data-testid={dataTestId}>
      {icon}
      <Box className={classes.content}>
        {title && (
          <Typography variant="h4" align="center" className={classes.title}>
            {title}
          </Typography>
        )}
        <Typography variant="subtitle1" align="center" className={classes.description}>
          {description}
        </Typography>
        {action && (
          <Box display="flex" className={classes.title}>
            {action}
          </Box>
        )}
        {children && children}
      </Box>
    </Box>
  );
};

export default NonIdealState;
