import { Folder, Project, Report } from "fond/types";

export type EntityType = "folder" | "project" | "report" | "multi_project" | "multi_report";

export type ResourceEntity = Folder | Project | Report;

export const EntityLabel: Record<EntityType, string> = {
  folder: "folder",
  project: "project",
  report: "report",
  multi_project: "city planner",
  multi_report: "city planner report",
} as const;

export const ResourceType = {
  FOLDER: "folder",
  PROJECT: "project",
  MULTI_PROJECT: "city planner project",
  MULTI_REPORT: "city planner report",
  REPORT: "report",
} as const;
export type ResourceType = keyof typeof ResourceType;
