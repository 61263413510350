import { AgGridReact } from "@ag-grid-community/react";
import { Box } from "@mui/material";

import { useLicenseAssignmentList } from "fond/settings/AccountManagement/useLicenseAssignmentList";
import { UserAccountAllocationResponse } from "fond/types";
import { AgGrid } from "fond/widgets";

interface LicenseAssignmentListProps {
  allocations: UserAccountAllocationResponse[] | undefined;
}

const LicenseAssignmentList: React.FC<LicenseAssignmentListProps> = ({ allocations }: LicenseAssignmentListProps) => {
  const { columns, gridOptions } = useLicenseAssignmentList();

  return (
    <Box flexGrow={1} data-testid="license-assignment-list">
      <AgGrid
        columnDefs={columns}
        rowData={allocations?.filter((allocation: UserAccountAllocationResponse) => allocation?.License) ?? null}
        gridOptions={gridOptions}
        variant="outlined"
      />
    </Box>
  );
};

export default LicenseAssignmentList;
