import React from "react";
import { Box, Divider, FormControl, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import { FullMultiReport, MultiReportCategory, Report, SystemOfMeasurement } from "fond/types";

import CityReportMapSlider from "./CityReportMapSlider";
import NpvLegend from "./NpvLegend";

interface IProps {
  multiReport: FullMultiReport;
  reports: Report[];
  category: MultiReportCategory;
  systemOfMeaurement?: SystemOfMeasurement;
  onCategoryChange: (method: MultiReportCategory) => void;
  onSliderChange(values: number[]): void;
}

const CityReportMapControl: React.FC<IProps> = ({ multiReport, reports, category, systemOfMeaurement, onCategoryChange, onSliderChange }: IProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value as MultiReportCategory;
    if (value !== category) {
      onCategoryChange(value);
    }
  };

  return (
    <Box>
      <Typography pb={1}>Categorize by</Typography>
      <Box sx={{ minWidth: 172 }}>
        <FormControl fullWidth>
          <Select value={category} onChange={handleChange} margin="dense" size="small">
            <MenuItem value="Npv">NPV</MenuItem>
            <MenuItem value="Irr">IRR</MenuItem>
            <MenuItem value="Roi">ROI</MenuItem>
            <MenuItem value="CostPerMeter">Cost per {systemOfMeaurement === "imperial" ? "foot" : "meter"}</MenuItem>
            <MenuItem value="CostPerPassing">Cost per passing</MenuItem>
          </Select>
        </FormControl>
        <Divider sx={{ my: 2 }} />
        {category === "Npv" && <NpvLegend />}
        {category !== "Npv" && (
          <CityReportMapSlider
            multiReport={multiReport}
            reports={reports}
            category={category}
            systemOfMeaurement={systemOfMeaurement}
            onSliderChange={onSliderChange}
          />
        )}
      </Box>
    </Box>
  );
};

export default CityReportMapControl;
