import { Box, styled } from "@mui/material";

export const CardContent = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(5),
  paddingLeft: theme.spacing(5),
}));

export const CardPanel = styled(CardContent)(({ theme }) => ({
  margin: `${theme.spacing(4)} 0`,
  padding: `${theme.spacing(5)} 0`,
}));
