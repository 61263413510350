import React from "react";
import { Map } from "@mui/icons-material";
import { Box, Checkbox, Divider, FormControlLabel, Tooltip, Typography } from "@mui/material";

import { NO_PHASE } from "fond/constants";
import MapProvider from "fond/map/MapProvider";
import { ReportMap } from "fond/map/ReportMap";
import { Configuration, FullReport, HhpDataItem } from "fond/types";
import { LayerConfig, SublayerConfig } from "fond/types/ProjectLayerConfig";
import { formatNumber } from "fond/utils/number";
import { NonIdealState } from "fond/widgets";

import ReportRevenueMapSlider from "./ReportRevenueMapSlider";

import { MapContainer } from "../../report.styles";
import { MapCalloutContainer, MapLegendBox } from "../../ReportMapLegend/ReportMapLegend.styles";

interface IProps {
  report: FullReport;
  revenueMapConfiguration?: Configuration;
  layerVisibilities: Record<string, boolean>;
  layerConfigs: Array<LayerConfig | SublayerConfig>;
  onSliderChange(value: number[]): void;
  buildOrderMapPhases: string[];
  phaseRange: [number, number];
  toggleNoPhase(event: React.ChangeEvent<HTMLInputElement>): void;
  noPhasePassings: number;
}

const ReportRevenueMapTile: React.FC<IProps> = ({
  report,
  revenueMapConfiguration,
  layerVisibilities,
  onSliderChange,
  buildOrderMapPhases,
  phaseRange,
  toggleNoPhase,
  noPhasePassings,
}) => {
  const noPhaseKey = Object.keys(layerVisibilities).find((phase) => phase.endsWith(NO_PHASE));
  const [phaseStart, phaseEnd] = phaseRange;
  const selectedBuildPassings =
    report?.Schedules?.Hhp.filter((schedule: HhpDataItem) => schedule.AddressType === "All")
      .slice(phaseStart, phaseEnd + 1) // Both sides inclusive
      .reduce((sum, item) => sum + item.NewPassings, 0) || 0;
  const selectedPassingsCount = noPhaseKey && layerVisibilities[noPhaseKey] ? selectedBuildPassings + noPhasePassings : selectedBuildPassings;
  return (
    <MapContainer height="100%">
      {!report?.BuildOrderMap?.Phases || !report?.BuildOrderMap?.BoundingBox || !revenueMapConfiguration ? (
        <NonIdealState icon={<Map />} description="No map data available." size="small" />
      ) : (
        <>
          <MapProvider mapStyle="monochrome">
            <ReportMap
              layerVisibilities={layerVisibilities}
              reportID={report.ID}
              boundingBox={report.BuildOrderMap.BoundingBox}
              systemOfMeasurement={report.SystemOfMeasurement}
              config={revenueMapConfiguration}
            />
          </MapProvider>

          <MapLegendBox>
            <MapCalloutContainer mb={0.5}>
              <Box data-testid="report-map-date-range" py={1.5} px={1}>
                <ReportRevenueMapSlider onSliderChange={onSliderChange} buildOrderMapPhases={buildOrderMapPhases} phaseRange={phaseRange} />
                {noPhaseKey && (
                  <Tooltip title="Display the service locations that will not be constructed within the planning period.">
                    <FormControlLabel control={<Checkbox checked={layerVisibilities[noPhaseKey]} onChange={toggleNoPhase} />} label="No-build zone" />
                  </Tooltip>
                )}
                <Divider sx={{ my: 1 }} />
                <Typography sx={{ pr: 4 }} variant="content" component="p" fontSize={12} fontWeight={400}>
                  Service locations
                </Typography>
                <Typography variant="body3" component="span" fontWeight={500} lineHeight={2}>
                  {formatNumber(selectedPassingsCount)}
                </Typography>
              </Box>
            </MapCalloutContainer>
          </MapLegendBox>
        </>
      )}
    </MapContainer>
  );
};

export default ReportRevenueMapTile;
