import React, { useCallback, useEffect } from "react";
import { Check, FilterList } from "@mui/icons-material";
import { Box, Checkbox, ListItemIcon, ListItemText, ListSubheader } from "@mui/material";

import { useGetMultiProjectQuery } from "fond/api";
import { useAppSelector } from "fond/utils/hooks";
import localStorage from "fond/utils/localStorage";

import { MenuItem, Select } from "./LayerFilter.styles";

export type LayerFilterOption = { ID: string; Label: string; Type: "Option" } | { ID: string; Label: string; Type: "Category" };

interface LayerFilterProps {
  multiple?: boolean;
  options: LayerFilterOption[];
  excludedLayerIds: string[];
  setExcludedLayerIds: React.Dispatch<React.SetStateAction<string[]>>;
}

const LayerFilter: React.FC<LayerFilterProps> = ({ excludedLayerIds, setExcludedLayerIds, options, multiple = false }: LayerFilterProps) => {
  const multiProjectId = useAppSelector((state) => state.project.projectId);
  const { data: multiProject } = useGetMultiProjectQuery(multiProjectId);
  const optionLength = options.filter(({ Type }) => Type === "Option").length;
  const optionIds = options.map(({ ID }) => ID);

  useEffect(() => {
    if (multiProject) {
      localStorage.setItem(`state.project.projects[${multiProject.ID}].layerFilter`, excludedLayerIds);
    }
  }, [multiProject, excludedLayerIds]);

  const handleOnLayerSelect = useCallback(
    (layerId: string) => (event: React.MouseEvent<HTMLLIElement>) => {
      event.stopPropagation();
      if (multiple) {
        setExcludedLayerIds((prev) => (prev?.includes(layerId) ? prev.filter((id) => id !== layerId) : [...(prev || []), layerId]));
      } else {
        setExcludedLayerIds((prev) => [...prev.filter((id) => !optionIds.includes(id)), ...optionIds.filter((id) => id !== layerId)]);
      }
    },
    [multiple, setExcludedLayerIds, optionIds]
  );

  const getMenuItem = (option: LayerFilterOption) => {
    if (option.Type === "Category")
      return (
        <ListSubheader key={option.ID} sx={{ fontSize: 13, lineHeight: 2, color: "grey" }}>
          {option.Label}
        </ListSubheader>
      );
    const checked = !excludedLayerIds?.includes(option.ID);

    return (
      <MenuItem key={option.ID} onClick={handleOnLayerSelect(option.ID)} value={option.ID} dense>
        <ListItemIcon>
          {multiple && <Checkbox checked={checked} disableRipple />}
          {!multiple && checked && <Check />}
        </ListItemIcon>
        <ListItemText primary={option.Label} />
      </MenuItem>
    );
  };

  return (
    <Select
      displayEmpty
      size="small"
      multiple
      value={excludedLayerIds.filter((id) => options.some(({ ID }) => ID === id)) || []}
      renderValue={() => {
        const value = optionLength - (excludedLayerIds?.length || 0);
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FilterList color="primary" sx={{ fontSize: 16, mr: 0.5 }} /> {value > 0 ? `Filters (${value})` : "Filter"}
          </Box>
        );
      }}
    >
      {options.map(getMenuItem)}
    </Select>
  );
};

export default LayerFilter;
