import React, { forwardRef, useMemo, useRef } from "react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { GridApi, GridOptions, GridReadyEvent, ModuleRegistry } from "@ag-grid-community/core";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { AgGridReact, AgGridReactProps } from "@ag-grid-community/react";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection";
import { RichSelectModule } from "@ag-grid-enterprise/rich-select";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { ServerSideRowModelModule } from "@ag-grid-enterprise/server-side-row-model";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { Box, CSSObject } from "@mui/material";

import { areWeTestingWithJest } from "fond/utils";

import BlockSpinner from "../BlockSpinner";

import "@ag-grid-community/styles/ag-grid.css";
import "./theme/ag-theme-compact.scss";
import "./theme/ag-theme-standard.scss";
import "./theme/ag-theme-large.scss";

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  CsvExportModule,
  ExcelExportModule,
  ClipboardModule,
  FiltersToolPanelModule,
  MenuModule,
  RangeSelectionModule,
  RichSelectModule,
  RowGroupingModule,
  ServerSideRowModelModule,
  SetFilterModule,
]);

interface IProps extends AgGridReactProps {
  /**
   * The grid works out the best width for columns
   * @default true
   */
  containerProps?: CSSObject;
  gridOptions?: GridOptions;
  variant?: "borderless" | "outlined";
  size?: "compact" | "standard" | "large";
}

const AgGrid = forwardRef<AgGridReact, IProps>(
  (
    { containerProps = { height: "100%", width: "100%" }, gridOptions, onGridReady, variant = "borderless", size = "standard", ...gridProps }: IProps,
    ref
  ) => {
    const gridApi = useRef<GridApi | null>();
    const wrapperRef = useRef<HTMLElement | null>(null);

    const defaultOptions: GridOptions = useMemo(
      () => ({
        columnMenu: "legacy",
        // Sets default column values (These can be set individually in column definitions)
        defaultColDef: {
          sortable: true,
          resizable: true,
          filter: true,
          enableRowGroup: true,
        },
        sideBar: {
          toolPanels: [
            {
              id: "filters",
              labelDefault: "Filters",
              labelKey: "filters",
              iconKey: "filter",
              toolPanel: "agFiltersToolPanel",
              toolPanelParams: {
                suppressExpandAll: true,
                suppressFilterSearch: true,
              },
            },
          ],
        },
        popupParent: document.querySelector("body") || undefined,
        reactiveCustomComponents: true,
        suppressContextMenu: false,
        suppressCellFocus: true,
        suppressDragLeaveHidesColumns: true,
        rowGroupPanelShow: "always",
        // AgGrid currently does not remove the loadingOverlay when running jest tests
        // if the data loaded is an empty array (indicating it should show the empty rows component)
        loadingOverlayComponent: areWeTestingWithJest() ? undefined : BlockSpinner,
      }),
      []
    );

    /**
     * The grid has initialised
     */
    const handleOnGridReady = (event: GridReadyEvent) => {
      gridApi.current = event.api;

      // To support popups within floating windows we need to set the popup parent
      event.api.setGridOption("popupParent", handleGetDocument().body);

      onGridReady?.(event);
    };

    const options = { ...defaultOptions, ...gridOptions };

    /**
     * Allows overriding what document is used. Currently used by Drag and Drop.
     * We need to specify this to support loading the grid inside a floating window.
     */
    const handleGetDocument = (): Document => {
      return wrapperRef.current?.ownerDocument || window.document;
    };

    return (
      <Box sx={containerProps} className={`ag-theme-${size} ${variant}`} data-testid="ag-grid" ref={wrapperRef}>
        <AgGridReact ref={ref} {...gridProps} gridOptions={options} onGridReady={handleOnGridReady} getDocument={handleGetDocument} />
      </Box>
    );
  }
);

AgGrid.displayName = "AgGrid";
export default AgGrid;
