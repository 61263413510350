import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";

import { Card } from "ui/Card";

import { NoContentIcon } from "fond/svg_icons";

export const CreateAccountPanel: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Card
      title="No content?"
      description={
        <>
          <Typography variant="body1" fontWeight={700}>
            It looks like you are not part of an account & have not been shared content.
          </Typography>
          <Typography maxWidth={400}>
            If you're the first person from your company using FOND, let's create a shared workspace for others to join.
          </Typography>
        </>
      }
      icon={<NoContentIcon />}
      buttonText="Create account"
      onClick={() => {
        navigate("/createaccount");
      }}
    />
  );
};
