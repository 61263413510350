import React, { useEffect, useMemo, useState } from "react";

import { NO_PHASE } from "fond/constants";
import mixpanel from "fond/mixpanel";
import { FullReport, HhpDataItem, type Schedules } from "fond/types";
import { generateBuildOrderMapConfiguration } from "fond/utils/buildOrderTransformers";
import { selectLayersFromConfig } from "fond/utils/configurations";
import { ZoomableGridCard } from "fond/widgets/ZoomableGridCard";

import ReportRevenueMapTile from "./ReportRevenueMapTile";

interface IProps {
  report: FullReport;
  visualizationType: keyof typeof Schedules;
}

const ReportRevenueMap: React.FC<IProps> = ({ report, visualizationType }: IProps) => {
  const [layerVisibilities, setLayerVisibilities] = useState<Record<string, boolean>>({});
  const [isFullscreenOpen, setIsFullscreenOpen] = useState(false);

  const buildOrderMapPhases = report?.BuildOrderMap.Phases?.filter((phase) => phase !== NO_PHASE) ?? [];
  const [phaseRange, setPhaseRange] = useState<[number, number]>([0, buildOrderMapPhases.length - 1]);

  // Calculate the passings in the `NO_PHASE` phase.
  const allPassings =
    report?.Schedules?.Hhp.filter((schedule: HhpDataItem) => schedule.AddressType === "All").reduce((sum, item) => sum + item.NewPassings, 0) || 0;
  const passingCoverage = report?.CoveragePassings || 1;
  const noPhasePassings = Math.floor((allPassings / passingCoverage) * (1 - passingCoverage));

  // cost to serve style configurations for service locations are generated in the FE based on CostBinRanges;
  // this is in contrast to projects where styles are determined by the backend.
  const revenueMapConfiguration = useMemo(() => {
    if (!report?.BuildOrderMap?.Phases) return undefined;
    return generateBuildOrderMapConfiguration(report.BuildOrderMap.Phases);
  }, [report?.BuildOrderMap?.Phases]);

  const layerConfigs = useMemo(
    () => (revenueMapConfiguration && selectLayersFromConfig(revenueMapConfiguration, ["SUBLAYER"])) || [],
    [revenueMapConfiguration]
  );

  useEffect(() => {
    setLayerVisibilities(
      Object.fromEntries(
        layerConfigs.map((layerConfig) => {
          return [layerConfig.ID, true];
        })
      )
    );
  }, [layerConfigs, visualizationType]);

  const onSliderChange = (value: [number, number]) => {
    setPhaseRange(value);
    const dateRangeKeys = Array.from(Array(buildOrderMapPhases.length).keys());
    const selectedDateKeys = dateRangeKeys.slice(value[0], value[1] + 1);
    setLayerVisibilities((prev) => {
      const keys = Object.keys(prev);
      const result: Record<string, boolean> = {};
      keys.forEach((key, index) => {
        result[key] = selectedDateKeys.includes(index);
      });
      return result;
    });

    mixpanel.track("Report", "Revenue", "Changed build map layers visibility");
  };

  const toggleNoPhase = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLayerVisibilities((prevLayerVisibilities) => {
      const clone = { ...prevLayerVisibilities };
      const noPhaseKey = Object.keys(prevLayerVisibilities).find((phase) => phase.endsWith(NO_PHASE));
      if (noPhaseKey) clone[noPhaseKey] = event.target.checked;
      return clone;
    });
  };

  const mapTileProps = {
    report,
    revenueMapConfiguration,
    layerVisibilities,
    layerConfigs,
    onSliderChange,
    buildOrderMapPhases,
    phaseRange,
    toggleNoPhase,
    noPhasePassings,
  };

  return (
    <ZoomableGridCard breakpoints={{ lg: 6, xs: 12 }} title="Build map" height={485}>
      <ReportRevenueMapTile {...mapTileProps} />
    </ZoomableGridCard>
  );
};

export default ReportRevenueMap;
