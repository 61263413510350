import { Paper, styled } from "@mui/material";
import { blue } from "@mui/material/colors";

export const ReportConfigurationSection = styled("fieldset")`  border: none;
  padding: 0;
  margin: 0;

  &:not(.is-editing) {
    input,
    button,
    .Mui-disabled {
      cursor: not-allowed;
    }
  }
`;

export const Disclaimer = styled(Paper)`  background-color: ${blue[50]};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(4)};
`;

Disclaimer.defaultProps = {
  elevation: 0,
};
