import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { AgGridReact } from "@ag-grid-community/react";
import SearchIcon from "@mui/icons-material/Search";
import { Box } from "@mui/material";

import { AgGrid } from "fond/widgets";

import { useUserList } from "./useUserList";

import { Container, UserSearchField } from "./UserList.styles";

const UserList: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [userSearch, setUserSearch] = useState("");
  const gridRef = useRef<AgGridReact>(null);
  const { userAccountAllocations, columns, gridOptions } = useUserList();

  /**
   * Set search field value and AgGrid filter if admin is redirected to /settings/users from request license notification
   */
  const shouldAllocateLicense = searchParams.get("allocateLicense") === "true";
  const licenseAllocationUserId = searchParams.get("userEmail");
  useEffect(() => {
    if (shouldAllocateLicense && licenseAllocationUserId) {
      setUserSearch(licenseAllocationUserId);
      gridRef.current?.api?.setGridOption("quickFilterText", licenseAllocationUserId);
    }
  }, [shouldAllocateLicense, licenseAllocationUserId]);

  const handleOnFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setUserSearch(event.target.value);
    gridRef.current?.api?.setGridOption("quickFilterText", event.target.value ?? "");
  };

  return (
    <Container>
      <UserSearchField
        id="user-search-field"
        data-testid="user-search-field"
        name="search"
        size="small"
        placeholder="Search by keywords"
        value={userSearch}
        onChange={handleOnFilterChange}
        InputProps={{ startAdornment: <SearchIcon /> }}
      />

      <Box flexGrow={1} data-testid="user-list">
        <AgGrid ref={gridRef} columnDefs={columns} rowData={userAccountAllocations ?? null} gridOptions={gridOptions} variant="outlined" />
      </Box>
    </Container>
  );
};

export default UserList;
