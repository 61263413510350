import { Box, css, styled } from "@mui/material";

import { fixSsrWarning } from "fond/utils/css";

export const GridCardHeader = styled(Box)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.spacing(1)} ${theme.spacing(2)};
    border-bottom: 1px solid ${theme.palette.divider};
    min-height: 54px;

    & > h6.MuiTypography-root:first-child ${fixSsrWarning} {
      line-height: 32px;
    }

    & > .grid-card-header-right {
      margin-left: auto;
    }
  `
);
