import React, { useContext, useMemo, useRef, useState } from "react";
import {
  CellEditingStoppedEvent,
  ColDef,
  GridOptions,
  IRowNode,
  ProcessCellForExportParams,
  SelectionChangedEvent,
  ValueFormatterParams,
  ValueSetterParams,
} from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { Delete, Undo } from "@mui/icons-material";
import { Alert, Box, Button, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import { isEmpty } from "lodash";

import { Progress } from "ui";
import { StepStatus } from "ui/Progress/Progress";

import { MapContext } from "fond/map/MapProvider";
import { AgGrid } from "fond/widgets";
import { stringComparator } from "fond/widgets/AgGrid";
import DataGridToolbar from "fond/widgets/DataGrid/DataGridToolbar";

import { setFeatureProperty } from "../helper";

import { SubareaProperties } from "./AreaDrawPanel";

type CarveStatus = "Idle" | "Carving" | "Processing";
type StatusMap = Record<CarveStatus, StepStatus>;

const statusMap: { Blocks: StatusMap; Subareas: StatusMap } = {
  Blocks: {
    Processing: "Success",
    Carving: "InProgress",
    Idle: "Idle",
  },
  Subareas: {
    Processing: "InProgress",
    Carving: "Idle",
    Idle: "Idle",
  },
};

interface AreaListProps {
  autoCarving: CarveStatus;
  rowData: SubareaProperties[] | null;
  onReset(): void;
}

const AreaList: React.FC<AreaListProps> = ({ autoCarving, onReset, rowData }: AreaListProps) => {
  const { map, drawControl } = useContext(MapContext);
  const [selectedNodes, setSelectedNodes] = useState<IRowNode[]>([]);
  const gridRef = useRef<AgGridReact>(null);

  const columns: ColDef[] = useMemo(
    () => [
      {
        headerName: "Name",
        field: "name",
        flex: 1,
        comparator: stringComparator,
        resizable: false,
        suppressHeaderMenuButton: true,
        editable: true,
        valueSetter: (params: ValueSetterParams): boolean => {
          const names = rowData?.map(({ name }) => name.toLowerCase()).filter((name) => name !== params.oldValue) || [];
          if (isEmpty(params.newValue) || names.includes(params.newValue.toLowerCase())) return false;
          params.data.name = params.newValue;
          return true;
        },
        sort: "asc",
        suppressPaste: true,
      },
      {
        headerName: "Path type",
        field: "importMethod",
        width: 100,
        resizable: false,
        suppressHeaderMenuButton: true,
        valueFormatter: (params: ValueFormatterParams) => (params.value === "area_select_underground" ? "Underground" : "Aerial"),
        editable: true,
        cellEditor: "agRichSelectCellEditor",
        cellEditorParams: {
          values: ["area_select_underground", "area_select_aerial"],
          formatValue: (value: string) => (value === "area_select_underground" ? "Underground" : "Aerial"),
        },
      },
    ],
    [rowData]
  );

  if (!map?.hasControl(drawControl.current)) return null;

  /**
   * Callback that allows the user to delete subareas
   */
  const handleOnDelete = () => {
    gridRef.current?.api?.forEachNode((node) => {
      if (node.isSelected()) {
        drawControl.current?.delete(node.data.id);
      }
    });

    map?.fire("draw.delete");
    map?.fire("draw.selectionchange");
  };

  /**
   * Callback function for handling the selection of a row
   */
  const handleOnSelectionChanged = (event: SelectionChangedEvent) => {
    // If the user is attempting to deselect the current version without selecting another then we re-select
    let selection: IRowNode[] = [];
    event.api.forEachNode((node) => {
      if (node.isSelected()) {
        selection.push(node);
      }
    });
    setSelectedNodes(selection);
  };

  // When copying values we want the raw data, not the valueFormatter data
  const processCellForClipboard = (params: ProcessCellForExportParams) => {
    return params.value;
  };

  const gridOptions: GridOptions = {
    animateRows: true,
    rowGroupPanelShow: "never",
    sideBar: false,
    pagination: false,
    suppressMovableColumns: true,
    domLayout: "normal",
    cellSelection: true,
    processCellForClipboard,
    singleClickEdit: true,
    suppressHeaderFocus: true,
    suppressCellFocus: false,
    suppressCutToClipboard: true,
    rowSelection: {
      mode: "multiRow",
      checkboxes: true,
    },
    stopEditingWhenCellsLoseFocus: true,
    onCellEditingStopped: (event: CellEditingStoppedEvent) => {
      // Only save the value if a valid change has been made
      if (event.valueChanged) {
        setFeatureProperty(drawControl.current, `${event.data.id}`, event.column.getId(), event.newValue);
      }
    },
  };

  return (
    <>
      {((rowData && rowData?.length > 0) || autoCarving !== "Idle") && (
        <Typography fontWeight="500" sx={{ mb: 2 }}>
          Subareas
        </Typography>
      )}

      {(autoCarving === "Carving" || autoCarving === "Processing") && (
        <>
          <Alert severity="info" sx={{ fontWeight: 500, mb: 1 }}>
            Creating subareas
          </Alert>
          <Progress
            steps={[
              { ID: "Boundary", Label: "Define area to carve", Status: "Success" },
              {
                ID: "Blocks",
                Label: "Retrieve census blocks",
                Status: statusMap.Blocks[autoCarving],
              },
              {
                ID: "Optimal",
                Label: "Determine subareas",
                Status: statusMap.Subareas[autoCarving],
              },
            ]}
          />
        </>
      )}

      {rowData && rowData.length > 0 && (
        <>
          <Alert severity="success" sx={{ fontWeight: 500, mb: 2 }}>
            Subareas created
          </Alert>
          {autoCarving === "Idle" && (
            <>
              <Box sx={{ pl: 2, pr: 1 }}>
                <DataGridToolbar
                  selected={selectedNodes.length}
                  title={`${rowData?.length} subareas`}
                  actions={
                    <Tooltip title="Delete">
                      <IconButton color="primary" data-testid="delete-row-button" onClick={handleOnDelete} size="small">
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  }
                  size="small"
                />
              </Box>
              <AgGrid
                ref={gridRef}
                columnDefs={columns}
                rowData={rowData}
                gridOptions={gridOptions}
                containerProps={{ height: 300 }}
                variant="outlined"
                size="compact"
                onSelectionChanged={handleOnSelectionChanged}
              />
            </>
          )}
        </>
      )}
      <Divider sx={{ mt: 2 }} />
      <Button
        fullWidth
        sx={{ my: 0.5, justifyContent: "left" }}
        startIcon={<Undo sx={{ height: 20 }} />}
        onClick={onReset}
        disabled={autoCarving !== "Idle"}
      >
        <Typography color={(theme) => theme.palette.common.black} fontWeight={500} fontSize={13}>
          Reset
        </Typography>
      </Button>
      <Divider />
    </>
  );
};

export default AreaList;
