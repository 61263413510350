import React, { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import LocationCityIcon from "@mui/icons-material/LocationCityOutlined";
import { Box, Container, Divider, Tab, Tabs, Typography } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";

import { selectFolderById, useGetFoldersQuery, useGetMultiProjectQuery } from "fond/api";
import { NavigateError } from "fond/navigation/NavigateError";
import { loadMultiProject } from "fond/project/redux";
import { Navigation } from "fond/projects";
import { Store } from "fond/types";
import { useAppDispatch } from "fond/utils/hooks";
import { PageContent } from "fond/widgets";

import CityReportFolderPageContent from "../CityReportFolder/CityReportFolderPageContent";

import CityPlannerFolderPageContent from "./CityPlannerFolderPageContent";
import ViewButton from "./ViewButton";

interface RouteParams {
  multiProjectFolderId: string;
}

/**
 * A top level component used for displaying a city planner folder.
 */
const CityPlannerFolder: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { isFetching: isFoldersFetching } = useGetFoldersQuery(undefined);
  const { multiProjectFolderId } = useParams<keyof RouteParams>();

  const currentFolder = useSelector((state: Store) => (multiProjectFolderId ? selectFolderById(state, multiProjectFolderId) : undefined));
  const folderName = currentFolder?.MultiProject?.Name ?? "";
  const { data: multiProject } = useGetMultiProjectQuery(currentFolder?.MultiProject?.ID ?? skipToken);
  const [loaded, setLoaded] = useState(false);

  const tabContents = useMemo(
    () => [
      {
        label: "Plan",
        stateLocation: "plan",
        value: 0,
        component: <CityPlannerFolderPageContent multiProject={multiProject} multiProjectLoaded={loaded} />,
      },
      {
        label: "Reports",
        stateLocation: "report",
        value: 1,
        component: <CityReportFolderPageContent multiProject={multiProject} />,
      },
    ],
    [multiProject, loaded]
  );

  const [selectedTabIndex, setSelectedTabIndex] = useState(tabContents.find((content) => content.stateLocation === location.state?.tab)?.value || 0);

  const handleOnTabSelect = (_: SyntheticEvent<Element, Event>, value: number) => {
    setSelectedTabIndex(value);
  };

  useEffect(() => {
    if (multiProject?.ID) {
      dispatch(loadMultiProject({ uuid: multiProject?.ID })).then(async () => {
        setLoaded(true);
      });
    }
  }, [dispatch, multiProject?.ID]);

  /**
   * If multiProjectFolderId is not null but currentFolder is null, it indicates that the current user has no access to the multi project,
   * redirect to projects landing page.
   */
  if (multiProjectFolderId && !currentFolder && !isFoldersFetching) {
    return <NavigateError to="/404" resourceType="MULTI_PROJECT" />;
  }

  return (
    <PageContent
      windowTitle={folderName}
      title={
        <Box display="flex" alignItems="center">
          <LocationCityIcon color="primary" sx={{ mr: 1 }} />
          <Typography variant="h1" data-testid="page-title">
            {folderName}
          </Typography>
        </Box>
      }
      action={<ViewButton folder={currentFolder} />}
      mainContent={
        <Container>
          <Tabs onChange={handleOnTabSelect} value={selectedTabIndex}>
            {tabContents.map((tab) => (
              <Tab key={tab.label} label={tab.label} value={tab.value} data-testid={`tab-${tab.value}`} />
            ))}
          </Tabs>
          <Divider />
          <Box mt={2}>{tabContents[selectedTabIndex].component}</Box>
        </Container>
      }
      navigation={<Navigation />}
      testId="multi-project-content"
    />
  );
};

export default CityPlannerFolder;
