import { ValidationErrors } from "final-form";
import { coerce, lt } from "semver";

import { versionsSlice } from "fond/api";
import { REPORT_BOM_MIN_TOOL_VERSION, REPORT_CTS_MIN_TOOL_VERSION } from "fond/constants";
import { useAppDispatch } from "fond/utils/hooks";

import { ReportFormData, RevenueFormConfiguration, VersionError } from "../../types";

export const useFormValidation = () => {
  const dispatch = useAppDispatch();

  return async (values: ReportFormData): Promise<ValidationErrors> => {
    const revenueValue = values.ReportConfiguration?.Revenue?.RevenueConfigurations;

    if (values.Version) {
      // Validate version has completed design & is not an outdated project
      const versionStatus = values.Version.ID ? await dispatch(versionsSlice.endpoints.getVersionStatus.initiate(values.Version.ID)) : undefined;
      if (!versionStatus?.data?.HasSolution) {
        return { Version: VersionError.INCOMPLETE_DESIGN };
      } else {
        const outdated = versionStatus.data.Workflow?.some(({ ToolName, ToolVersion }) => {
          const version = coerce(ToolVersion);
          return (
            (version && ToolName === "cost_to_serve" && lt(version, REPORT_CTS_MIN_TOOL_VERSION)) ||
            (version && ToolName === "configurable_bom_orca" && lt(version, REPORT_BOM_MIN_TOOL_VERSION))
          );
        });
        if (outdated) return { Version: VersionError.OUTDATED_PROJECT };
      }

      // Validate version has BOM & costs
      const versionInfo = values.Version.ID ? await dispatch(versionsSlice.endpoints.getVersion.initiate(values.Version.ID)) : undefined;
      if (versionInfo?.data?.Architecture?.BOM) {
        const hasCosts = versionInfo.data?.Architecture.BOM.Categories.some(({ Rows }) => Rows.some(({ Cost }) => Cost > 0));
        if (!hasCosts) return { Version: VersionError.BOM_WITHOUT_COSTS };
      } else {
        return { Version: VersionError.NO_BOM };
      }

      // Validate version has a demand model. Note since the revenueValue is based on the
      // demand model we can use this instead of requesting the full demand model from the project.
      if (revenueValue === undefined || (Array.isArray(revenueValue) && revenueValue.length === 0)) {
        return { Version: VersionError.NO_DEMAND_MODEL };
      }

      // Validate the Revenue section
      if (Array.isArray(revenueValue)) {
        const invalidRows = revenueValue.some(({ TakeRateMin, TakeRateMax }: RevenueFormConfiguration) => TakeRateMin >= TakeRateMax);
        if (invalidRows) return { ReportConfigurationValidation: "Each address must have a minimum take rate less than its maximum value" };
      }
    }

    return undefined;
  };
};
