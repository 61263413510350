import React from "react";
import { Cancel, Check, Error, Schedule } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";

import { Container, Step, StepLabel } from "./Progress.styles";

export type StepStatus = "Success" | "Error" | "InProgress" | "Idle" | "Cancelling" | "NotReady";

export type Step = { ID: string; Label: string; Status: StepStatus; Icon?: React.ReactNode };
export interface ProgressProps {
  steps: Step[];
}

const icons: Record<StepStatus, React.ReactNode> = {
  Success: <Check />,
  Error: <Error />,
  InProgress: <CircularProgress size={14} color="inherit" data-testid="CircularProgress" />,
  Idle: <Schedule />,
  Cancelling: <Cancel />,
  NotReady: null,
};

const Progress: React.FC<ProgressProps> = ({ steps }: ProgressProps) => {
  return (
    <Container>
      {steps.map(({ ID, Status, Label, Icon }) => (
        <Step key={ID} className={Status}>
          <StepLabel noWrap>{Label}</StepLabel>
          {Icon || icons[Status]}
        </Step>
      ))}
    </Container>
  );
};

export default Progress;
