import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { DrawCreateEvent, DrawModeChangeEvent, DrawUncombineEvent } from "@mapbox/mapbox-gl-draw";
import staticMode from "@mapbox/mapbox-gl-draw-static-mode";
import { HighlightAlt } from "@mui/icons-material";
import { Alert, AlertTitle, Box, Button } from "@mui/material";
import { Feature, FeatureCollection, GeoJsonProperties, MultiPolygon, Polygon } from "geojson";
import { isEmpty } from "lodash";
import { passing_draw_polygon as passingDrawPolygon } from "mapbox-gl-draw-passing-mode";

import { LoadingButton } from "ui";

import { useGetMultiProjectQuery, useUpdateMultiProjectMutation } from "fond/api";
import AutoCarvePopup from "fond/cityPlanner/AreaPanel/AutoCarvePopup";
import DrawHandler from "fond/draw/DrawHandler";
import { intersect } from "fond/draw/helper";
import readOnlySelectMode from "fond/draw/modes/base/readOnlySelect";
import { HistoryContext, useHistoryContext } from "fond/history";
import { useDataset } from "fond/hooks/useDataset";
import { MapContext } from "fond/map/MapProvider";
import { EditMode, setEditMode } from "fond/project/redux";
import { feature as toFeature, featureCollection, multiPolygon } from "fond/turf";
import { MultiProjectAreaImportMethod, Store, UpdateMultiProjectAreaEntry } from "fond/types";
import { isAnyPolygon, isPolygon, isValuePolygon } from "fond/types/geojson";
import { makeUuid } from "fond/utils";
import { layerPalette } from "fond/utils/colors";
import { useAppDispatch } from "fond/utils/hooks";
import { generateUnusedName } from "fond/utils/naming";
import { BlockSpinner, useStackedNavigationContext } from "fond/widgets";
import StackedNavigationHeader from "fond/widgets/StackedNavigation/StackedNavigationHeader";
import AutoCombineWorker from "fond/workers/carve?worker";

import { setFeatureProperty } from "../helper";

import areaDrawStyles from "./areaDrawStyles";
import AreaList from "./AreaList";
import { carveToolbarConfig } from "./toolbar";

export type SubareaProperties = {
  id: string;
  name: string;
  minCount: number | null;
  exactCount: number | null;
  importMethod: MultiProjectAreaImportMethod;
  color: string;
  censusBlockGroupIds: string[];
};

export type SubareaFeature = Feature<Polygon | MultiPolygon, SubareaProperties>;

const AreaDrawPanel: React.FC = () => {
  const dispatch = useAppDispatch();
  const multiProjectId = useSelector((state: Store) => state.project.projectId);
  const { data: multiProject } = useGetMultiProjectQuery(multiProjectId);
  const { goBack, clear } = useStackedNavigationContext();
  const { map, drawControl, setDrawMode, isDrawing } = useContext(MapContext);
  const { set: setHistory, state: historyState, clear: clearHistory } = useHistoryContext<FeatureCollection>(HistoryContext);
  const [updateMultiProject] = useUpdateMultiProjectMutation();
  const [mode, setMode] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [autoCarving, setAutoCarving] = useState<"Idle" | "Carving" | "Processing">("Idle");
  const [showCarvePopup, setShowCarvePopup] = useState(true);
  const { isLoading: isDatasetLoading, featuresWithin: datasetFeaturesWithin } = useDataset({
    key: "census-block-groups-2024",
    accountId: multiProject?.Account.ID ?? null,
  });
  const workerRef = useRef<Worker | null>(null);
  const [rowData, setRowData] = useState<SubareaProperties[] | null>(null);

  /**
   * Get the multiproject Subareas & convert them to multiple polygons ready to load as
   * the initial draw features.
   */
  const initialFeatures = useMemo(() => {
    if (multiProject?.Areas && multiProject.Areas.length > 0) {
      return multiProject?.Areas.map((area) =>
        toFeature(
          area.Boundary,
          { id: area.ID, name: area.Name, importMethod: area.ImportMethod, exactCount: 0, minCount: null, color: area.Style.Color },
          { id: `${area.ID}` }
        )
      );
    }

    return [];
  }, [multiProject?.Areas]);

  const getRowData = useCallback(() => {
    return map?.hasControl(drawControl.current)
      ? (drawControl.current?.getAll() as FeatureCollection<Polygon, SubareaProperties>).features
          .filter(isAnyPolygon)
          .filter(isValuePolygon)
          .map(({ properties }) => properties)
      : [];
  }, [drawControl, map]);

  /**
   * Sync the drawing features with the current state of history as the user
   * clicks undo/redo.
   */
  useEffect(() => {
    if (isDrawing) {
      if (isEmpty(historyState)) {
        drawControl.current.set(featureCollection(initialFeatures));
      } else {
        drawControl.current.set(historyState);
      }
      setRowData(getRowData());
    }
  }, [initialFeatures, isDrawing, drawControl, historyState, getRowData]);

  useEffect(() => {
    return () => {
      clearHistory();
      setDrawMode("no_feature");
    };
  }, [clearHistory, setDrawMode]);

  const handleModeChange = useCallback(
    (event: DrawModeChangeEvent) => {
      setMode(event.mode);
    },
    [setMode]
  );
  const handleAutoCarveComplete = useCallback(
    (rawFeatures: Feature<Polygon | MultiPolygon, { addressCount: number; name?: string; ids: string[] }>[]) => {
      const names: string[] = [];

      const features = rawFeatures.map((f, i) => {
        const id = makeUuid();
        const name = generateUnusedName(f.properties.name ?? "Subarea", names);
        names.push(name);

        return toFeature(
          f.geometry,
          {
            id,
            name,
            importMethod: "area_select_underground",
            exactCount: f.properties.addressCount,
            minCount: null,
            color: layerPalette[i % layerPalette.length],
            censusBlockGroupIds: f.properties.ids,
          },
          { id }
        );
      });

      drawControl.current.set(featureCollection(features));
      setHistory(drawControl.current.getAll());
      setAutoCarving("Idle");
    },
    [drawControl, setHistory]
  );

  useEffect(() => {
    const autoCombineWorker = new AutoCombineWorker();

    autoCombineWorker.onmessage = (event) => {
      handleAutoCarveComplete(event.data);
    };

    workerRef.current = autoCombineWorker;

    // Clean up the worker when the component unmounts
    return () => {
      workerRef.current?.terminate();
    };
  }, [handleAutoCarveComplete]);

  const handleAutoCarve = useCallback(
    async (event: DrawCreateEvent) => {
      setAutoCarving("Carving");

      // Clear the existing subareas
      setRowData([]);
      drawControl.current.deleteAll();

      if (!isDatasetLoading && datasetFeaturesWithin) {
        event.features.filter(isPolygon).forEach(async (feature) => {
          const blockGroups = await datasetFeaturesWithin(feature.geometry);

          if (workerRef.current) {
            setAutoCarving("Processing");
            // We offload the processing of the blockGroups to a web worker background thread.
            workerRef.current.postMessage({ features: blockGroups.features.filter(isAnyPolygon), maxAddresses: 6_000 });
          }
        });
      }
    },
    [datasetFeaturesWithin, drawControl, isDatasetLoading]
  );

  useEffect(() => {
    map?.on("draw.modechange", handleModeChange);
    map?.on("draw.passing-create", handleAutoCarve);

    return () => {
      map?.off("draw.modechange", handleModeChange);
      map?.off("draw.passing-create", handleAutoCarve);
    };
  }, [map, handleModeChange, handleAutoCarve]);

  /**
   * Customised clipping function that clips the feature being drawn or updated so that it does not:
   * 1) Overlap any other subareas
   * 2) Extend beyond the city boundary
   */
  const clipFeature = useCallback((feature: Feature<Polygon | MultiPolygon>, all: Feature[]): Feature<Polygon | MultiPolygon> => {
    // Don't allow feature to overlap other subareas
    let newFeature = intersect(feature, all);

    return newFeature;
  }, []);

  /**
   * When the combine or uncombine event is fired we rename
   * the created features based on the original features & update feature counts.
   */
  const handleOnUncombine = useCallback(
    (event: DrawUncombineEvent) => {
      event.createdFeatures.forEach(({ id }, index) => {
        setFeatureProperty(drawControl.current, String(id), "id", id);
        if (index > 0 && id) {
          const newName = generateUnusedName(
            event.createdFeatures[0].properties?.name,
            drawControl.current.getAll().features.map((feat) => feat.properties?.name || "") || []
          );
          setFeatureProperty(drawControl.current, String(id), "name", newName);
        }
      });

      setHistory(drawControl.current.getAll());
    },
    [drawControl, setHistory]
  );

  const handleBack = () => {
    goBack();
    dispatch(setEditMode(EditMode.none));
  };

  const onReset = () => {
    drawControl.current.set(featureCollection(initialFeatures));
    clearHistory();
  };

  /**
   * Handles importing the currently draw features and updating the multiProject
   * with the new area boundaries.
   */
  const handleOnImport = () => {
    setIsSaving(true);
    if (drawControl.current.getMode() === "draw_polygon") {
      // Exit drawing mode & trash any partially drawn features
      drawControl.current.trash();
    }
    const importFeatures = drawControl.current.getAll().features.filter(isAnyPolygon) as Feature<Polygon | MultiPolygon, SubareaProperties>[];

    const areas: UpdateMultiProjectAreaEntry[] = importFeatures.map((feat) => {
      const newFeature = isPolygon(feat) ? multiPolygon([feat.geometry.coordinates]).geometry : (feat.geometry as MultiPolygon);

      return {
        Name: feat.properties.name,
        Boundary: newFeature,
        ImportMethod: feat.properties.importMethod,
        Style: {
          Color: feat.properties.color,
        },
        CensusBlockGroups: feat.properties.censusBlockGroupIds.map((id) => ({ ID: id })),
      };
    });

    updateMultiProject({ ID: multiProjectId, Areas: areas }).then(() => {
      setDrawMode("no_feature");
      dispatch(setEditMode(EditMode.none));
      clear();
    });
  };

  const getFeatureProperties = useCallback(
    (feature: Feature): GeoJsonProperties => {
      const color = layerPalette[(drawControl.current.getAll().features.length - 1) % layerPalette.length];

      return {
        id: String(feature.id),
        name: generateUnusedName("Subarea", drawControl.current.getAll().features.map((feat) => feat.properties?.name || "") || []),
        importMethod: "area_select_underground",
        minCount: null,
        exactCount: 0,
        color,
      };
    },
    [drawControl]
  );

  // The DrawHandler can crash if the user is fast-fingered and tries to start drawing before the map had loaded.
  if (!map || isDatasetLoading) {
    return <BlockSpinner containerProps={{ height: "100%" }} />;
  }

  return (
    <>
      <DrawHandler
        initialFeatures={initialFeatures}
        startingMode={initialFeatures.length > 0 ? "simple_select" : "passing_draw_polygon"}
        source="multiProject-source"
        styles={areaDrawStyles}
        autoClip
        // While editing is disabled we are overriding the default modes
        // to only allow ready only select & passing draw polygon (for autocarve).
        // If we re-enable editing there is no need to pass modes here.
        modes={{
          simple_select: readOnlySelectMode,
          passing_draw_polygon: passingDrawPolygon,
          static: staticMode,
        }}
        clipFeature={clipFeature}
        config={carveToolbarConfig}
        getFeatureProperties={getFeatureProperties}
        onUncombine={handleOnUncombine}
      />
      <StackedNavigationHeader onBackButtonClick={handleBack} title="Identify subareas " />
      <Box data-testid="draw-panel" sx={{ py: 2 }}>
        {rowData?.length === 0 && autoCarving === "Idle" && (
          <Alert severity="info" data-testid="layer-alert" sx={{ fontSize: 12 }}>
            <AlertTitle>City area</AlertTitle>
            Start defining your boundaries. Use the auto carve <HighlightAlt style={{ width: 16, height: 16, verticalAlign: "middle" }} /> tool to
            import subareas.
          </Alert>
        )}
        <AreaList onReset={onReset} rowData={rowData} autoCarving={autoCarving} />
        <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
          <Box>
            <Button color="primary" size="small" onClick={handleBack}>
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              size="small"
              onClick={handleOnImport}
              sx={{ ml: 1, px: 2 }}
              data-testid="finish-button"
              loading={isSaving}
              disabled={autoCarving !== "Idle"}
            >
              Finish
            </LoadingButton>
          </Box>
        </Box>
      </Box>
      {datasetFeaturesWithin && showCarvePopup && <AutoCarvePopup onClose={() => setShowCarvePopup(false)} isLoading={autoCarving !== "Idle"} />}
    </>
  );
};

export default AreaDrawPanel;
