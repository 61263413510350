import { Position, Properties } from "@turf/helpers";
import kinks from "@turf/kinks";
import { Feature, MultiPolygon, Polygon } from "geojson";
import spatialTree from "geojson-rbush";
import _ from "lodash";

import { fondServiceErrorCodes, requestJson } from "fond/api";
import { FondServiceLayerIds, inAddress, inParcel, inPole, inSpan, inStreet } from "fond/layers";
import { PolygonStatus } from "fond/project/polygon";
import * as turf from "fond/turf";
import { AddressFeature, ParcelFeature, PoleFeature, SpanFeature, StreetFeature } from "fond/types/geojson";
import { BaseDataLayerName, BaseDataLayers } from "fond/types/layer";

/**
 * Filter the 'layers' for features that are within the 'area' polygon.
 * For addresses this means the address point is within the area.
 * For streets or span this means any node of the linestring is within the area.
 * For parcels this means that the parcel geometry touches the area.
 * For poles this means any node close to a coordinate of a span.
 */
function filterBySelection(layers: BaseDataLayers, area: Feature<Polygon>): BaseDataLayers {
  const layersCopy = _.cloneDeep(layers);
  if (layers[inAddress]) {
    _.remove(layersCopy[inAddress].features, (f: AddressFeature) => !turf.booleanPointInPolygon(turf.point(f.geometry.coordinates), area));
  }
  if (layers[inStreet]) {
    _.remove(
      layersCopy[inStreet].features,
      (f: StreetFeature) => !f.geometry.coordinates.some((coord: Position) => turf.booleanPointInPolygon(turf.point(coord), area))
    );
  }
  if (layers[inParcel]) {
    _.remove(layersCopy[inParcel].features, (f: ParcelFeature) => turf.booleanDisjoint(f, area));
  }

  if (layers[inSpan]) {
    _.remove(
      layersCopy[inSpan].features,
      (f: SpanFeature) => !f.geometry.coordinates.some((coord: Position) => turf.booleanPointInPolygon(turf.point(coord), area))
    );
  }

  // After removing span, we can be left with poles that aren't near any span.  Remove these poles.
  if (layers[inPole]) {
    // Buffer each span endpoint by a small amount, then keep any poles that intersect this buffered region.
    const tree = spatialTree();
    tree.load(layersCopy[inSpan].features.flatMap((f: SpanFeature) => turf.buffer(f.geometry, 10, { units: "centimeters" })));
    _.remove(layersCopy[inPole].features, (f: PoleFeature) => !tree.collides(f));
  }

  return layersCopy;
}

function emptyLayers(): BaseDataLayers {
  return {
    [inAddress]: turf.featureCollection([]),
    [inStreet]: turf.featureCollection([]),
    [inParcel]: turf.featureCollection([]),
    [inSpan]: turf.featureCollection([]),
    [inPole]: turf.featureCollection([]),
  };
}

/**
 * Downloads the data within selectedArea and returns an updated project.polygon state
 */
interface AreaSelectDownloadInputs {
  selectedArea: Feature<Polygon>;
  downloadedArea: Feature<Polygon>;
  downloadedBaseData: BaseDataLayers;
  aerial: boolean;
}

interface AreaSelectDownloadOutputs {
  downloadedArea: Feature<Polygon | MultiPolygon> | null;
  downloadedBaseData: BaseDataLayers;
  incompleteData: { [K in keyof BaseDataLayers]: boolean };
  selectedArea: Feature<Polygon>;
  selectedData: BaseDataLayers;
  status: string;
  errorMessage?: string;
}

export default async function updateDataFromSelectedArea({
  selectedArea,
  downloadedArea,
  downloadedBaseData,
  aerial,
}: AreaSelectDownloadInputs): Promise<AreaSelectDownloadOutputs> {
  const incompleteData = {
    [inParcel]: false,
    [inStreet]: false,
    [inAddress]: false,
    [inSpan]: false,
    [inPole]: false,
  };

  // If there are any kinks we cant do turf.difference on the area.
  if (kinks(selectedArea).features.length > 0) {
    return {
      downloadedArea,
      downloadedBaseData,
      incompleteData,
      selectedArea,
      selectedData: emptyLayers(),
      status: PolygonStatus.InvalidArea,
    };
  }

  let downloadedAreaUpdate: Feature<Polygon | MultiPolygon, Properties> | null = downloadedArea;
  const downloadedBaseDataUpdate = { ...downloadedBaseData };

  if (aerial) {
    // Aerial data is generated on-the-fly, and could change depending on exactly what polygon is provided.
    // Given this, we always refetch aerial data when the user's polygon changes.

    let response;
    try {
      response = await requestJson("POST", "/v2/area-select/features-within", {
        layers: [FondServiceLayerIds.inSpan, FondServiceLayerIds.inPole],
        area: selectedArea,
      });
    } catch (error: any) {
      // FOND service will forbid a query if it exceeds a predefined limit
      if (error.code === fondServiceErrorCodes.tooManyFeatures) {
        return {
          downloadedArea,
          downloadedBaseData,
          incompleteData,
          selectedArea,
          selectedData: emptyLayers(),
          status: PolygonStatus.QueryTooLarge,
          errorMessage: error.message,
        };
      }
      throw error;
    }

    downloadedBaseDataUpdate[inSpan].features = response.Layers[FondServiceLayerIds.inSpan]?.features || [];
    downloadedBaseDataUpdate[inPole].features = response.Layers[FondServiceLayerIds.inPole]?.features || [];
    downloadedAreaUpdate = selectedArea;
  } else {
    // Unlike aerial data, we only fetch underground data if the user has selected an area
    // that wasn't selected before.

    // Request a download if the selected area has not been downloaded yet.
    const addedArea = turf.difference(selectedArea, downloadedArea);
    if (addedArea) {
      let response;

      try {
        response = await requestJson("POST", "/v2/area-select/features-within", {
          // We must request all the base data layers to support importing multiple layers from
          // either the addresses area select panel or streets area select panel
          layers: [FondServiceLayerIds.inAddress, FondServiceLayerIds.inParcel, FondServiceLayerIds.inStreet],
          area: addedArea,
          exclude: downloadedArea,
        });
      } catch (error: any) {
        // FOND service will forbid a query if it exceeds a predefined limit
        if (error.code === fondServiceErrorCodes.tooManyFeatures) {
          return {
            downloadedArea,
            downloadedBaseData,
            incompleteData,
            selectedArea,
            selectedData: emptyLayers(),
            status: PolygonStatus.QueryTooLarge,
            errorMessage: error.message,
          };
        }
        throw error;
      }

      let newData = {
        [inAddress]: response.Layers[FondServiceLayerIds.inAddress] || turf.featureCollection([]),
        [inParcel]: response.Layers[FondServiceLayerIds.inParcel] || turf.featureCollection([]),
        [inStreet]: response.Layers[FondServiceLayerIds.inStreet] || turf.featureCollection([]),
      };

      // check for incomplete layers
      for (const [key, layer] of Object.entries(newData)) {
        // Typescript doesn't seem to be able to infer that `key` will always
        // be a key of BaseDataLayers, so we cast here.
        downloadedBaseDataUpdate[key as keyof BaseDataLayers].features.push(...layer.features);
      }
      downloadedAreaUpdate = turf.union(turf.featureCollection([downloadedArea, selectedArea]));
    }
  }

  const selectedData = filterBySelection(downloadedBaseDataUpdate, selectedArea);

  for (let layer of Object.keys(selectedData)) {
    // Typescript doesn't seem to be able to infer that Object.keys() of a
    // BaseDataLayers only returns keys of BaseDataLayers, so we cast here.
    const key = layer as keyof BaseDataLayers;
    if (selectedData[key].features.length === 0) {
      incompleteData[key] = true;
    }
  }

  const expectedLayers = aerial ? [inSpan, inPole] : [inAddress, inParcel, inStreet];

  // if all expected layers are incomplete then this area is unsupported
  if (expectedLayers.every((k) => incompleteData[k as BaseDataLayerName])) {
    return {
      downloadedArea,
      downloadedBaseData: downloadedBaseDataUpdate,
      incompleteData,
      selectedArea,
      selectedData: emptyLayers(),
      status: PolygonStatus.UnsupportedArea,
    };
  }

  return {
    downloadedArea: downloadedAreaUpdate,
    downloadedBaseData,
    incompleteData,
    selectedArea,
    selectedData,
    status: PolygonStatus.ValidArea,
  };
}
