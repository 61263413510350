import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ColDef, GetRowIdParams, GridOptions, SelectionChangedEvent, ValueGetterParams } from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { Search } from "@mui/icons-material";
import { Box, Button, TextField, Typography } from "@mui/material";

import { MultiReport, Store } from "fond/types";
import { Modal, NonIdealState } from "fond/widgets";
import { AgGrid, dateValueFormatter } from "fond/widgets/AgGrid";

interface IProps {
  multiReports: MultiReport[];
  onClose(): void;
  onSelect?(multiReport: MultiReport): void;
}

const gridOptions: GridOptions = {
  animateRows: true,
  rowGroupPanelShow: "never",
  sideBar: false,
  pagination: true,
  suppressMovableColumns: true,
  suppressMenuHide: true,
  paginationPageSize: 10,
  paginationPageSizeSelector: [10, 20, 50, 100],
  domLayout: "normal",
  rowSelection: {
    mode: "singleRow",
    checkboxes: false,
    enableClickSelection: true,
  },
  getRowId: (params: GetRowIdParams) => params.data.ID,
  noRowsOverlayComponent: () => <NonIdealState title="This city planner has no report yet." description="Create a report to get started." />,
};
const CityReportSelectModal: React.FC<IProps> = ({ multiReports, onClose, onSelect }: IProps) => {
  const gridApi = useRef<AgGridReact>(null);
  const [selectedItem, setSelectedItem] = useState<MultiReport | null>(null);
  const currentUsername = useSelector((state: Store) => state.cognito.user?.username);

  const columns: ColDef[] = [
    {
      field: "Name",
      headerName: "Report name",
      flex: 3,
      minWidth: 80,
      width: 90,
      suppressHeaderMenuButton: true,
    },
    {
      field: "LastModifiedBy.Email",
      headerName: "Last modified by",
      flex: 2,
      suppressHeaderMenuButton: true,
      valueGetter: (params: ValueGetterParams) => {
        const username = params.data.LastModifiedBy.Email;
        return username === currentUsername ? "me" : username;
      },
    },
    {
      field: "LastModified",
      headerName: "Last modified",
      valueFormatter: dateValueFormatter,
      suppressHeaderMenuButton: true,
      filter: "agDateColumnFilter",
      sort: "desc",
    },
  ];

  const handleOnClick = () => {
    onClose();
    if (selectedItem && onSelect) {
      onSelect(selectedItem);
    }
  };

  const handleOnSelection = (event: SelectionChangedEvent) => {
    event.api.forEachNode((node) => {
      if (node.isSelected()) {
        setSelectedItem(node.data);
      }
    });
  };

  return (
    <Modal
      open
      header="Change displayed report"
      variant="primary"
      PaperProps={{ style: { minHeight: "80vh" } }}
      DialogContentProps={{ sx: { display: "flex", flexDirection: "column" } }}
      content={
        <>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography fontWeight={500}>Select a report to view</Typography>
            <TextField
              size="small"
              placeholder="Search by keywords"
              InputProps={{ startAdornment: <Search fontSize="small" /> }}
              onChange={(event) => gridApi.current?.api.setGridOption("quickFilterText", event.target.value ?? "")}
            />
          </Box>
          <Box mt={2} flexGrow={1} position="relative">
            <AgGrid
              ref={gridApi}
              columnDefs={columns}
              rowData={multiReports}
              gridOptions={gridOptions}
              variant="outlined"
              onSelectionChanged={handleOnSelection}
              containerProps={{
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
              }}
            />
          </Box>
        </>
      }
      actions={
        <>
          <Button onClick={onClose} sx={{ mr: 1 }}>
            Cancel
          </Button>
          <Button type="submit" onClick={handleOnClick}>
            Confirm
          </Button>
        </>
      }
    />
  );
};

export default CityReportSelectModal;
