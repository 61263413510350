const DEFAULT_FRACTION_DIGITS = 2;

/**
 * Will round a number to a set of significant decimals.
 *
 * This differs from `(number).toFixed` in that it will round the number and return a number
 * and only accounts for significant digits, not total number of decimal places.
 *
 * @param num - The number to round
 * @param significantDecimals - The number of significant decimals to round to
 * @returns the rounded number
 * @example
 *   roundFloat(1.2345) // 1.23
 *   roundFloat(1.2345, 3) // 1.235
 *   roundFloat(1.2345, 0) // 1
 */
export function roundFloat(num: number, significantDecimals = DEFAULT_FRACTION_DIGITS): number {
  if (significantDecimals < 0) {
    return num;
  }
  const factor = 10 ** significantDecimals;
  return Math.round(num * factor) / factor;
}

/**
 * Get the range of values for a given min/max and number of steps we want for that range.
 *
 * The range will be evenly distributed between, and inclusive of, the from and to values.
 *
 * This differs from a regular "range" in that this about ending with a fixed number of steps
 * whereas a regular range would take a step size and create a range based on the difference between
 * the 'from' and 'to'
 *
 * @param from - The starting point
 * @param to - The end point
 * @param steps - The number of steps to create a range for
 * @param significantDecimals - The number of significant decimals to round to. Defaults to 2
 * @example
 *   createSteppedRange(0.1, 0.6, 4) // [ 0.1, 0.2667, 0.4333, 0.6 ]
 * @returns
 */
export function createSteppedRange(from: number, to: number, steps: number, significantDecimals = DEFAULT_FRACTION_DIGITS): number[] {
  if (steps == null || steps <= 1) {
    // if there are no steps to create a range for we just care about the max value
    return [to];
  }

  const stepSize = (to - from) / (steps - 1);
  const range = [];
  for (let i = 0; i < steps; i++) {
    range.push(roundFloat(from + stepSize * i, significantDecimals));
  }
  return range;
}

/**
 * Format a number as a percentage string
 *
 * @param value - The number to format
 * @param maximumFractionDigits - The maximum number of significant decimals to round to. Defaults to 2
 * @returns the number formatted as a percentage
 * @example
 *   formatPercent(12.34) // "12.34%"
 *   formatPercent(12.34, 1) // "12.3%"
 *   formatPercent(null) // "-"
 */
export function formatPercent(value: number | null | undefined, maximumFractionDigits = DEFAULT_FRACTION_DIGITS): string {
  if (value === null || value === undefined || Number.isNaN(value)) return "-";
  // Intl.NumberFormat will multiply the value by 100 to get the percentage
  return new Intl.NumberFormat("en", { style: "percent", maximumFractionDigits }).format(value / 100);
}

/**
 * Format a fraction as a percent
 *
 * @param value - The number to format
 * @param maximumFractionDigits - The maximum number of significant decimals to round to. Defaults to 2
 * @returns the number formatted as a percentage
 * @example
 *   formatFractionPercent(0.1234) // "12.34%"
 *   formatFractionPercent(0.1234, ) // "12.3%"
 *   formatFractionPercent(null) // "-"
 */
export function formatFractionPercent(value: number | null | undefined, maximumFractionDigits = DEFAULT_FRACTION_DIGITS): string {
  if (value === null || value === undefined || Number.isNaN(value)) return "-";
  return formatPercent(value * 100, maximumFractionDigits);
}

/**
 * Format a fraction as a percent without the percentage symbol
 *
 * @param value - The number to format
 * @param maximumFractionDigits - The maximum number of significant decimals to round to. Defaults to 2
 * @returns the number formatted as a percentage
 * @example
 *   formatFraction(0.1234) // "12.34"
 *   formatFraction(0.1234, ) // "12.3"
 *   formatFraction(null) // "-"
 */
export function formatFraction(value: number | null | undefined, maximumFractionDigits = DEFAULT_FRACTION_DIGITS): string {
  if (value === null || value === undefined || Number.isNaN(value)) return "-";
  return formatNumber(value * 100, maximumFractionDigits);
}

/**
 * Format a number with commas and a maximum number of significant decimals
 *
 * @param value - The number to format
 * @param maximumFractionDigits - The number of significant decimals to round to. Defaults to 2
 * @returns the formatted number
 * @example
 *   formatNumber(1234.5678) // "1,234.57"
 *   formatNumber(1234.5678, 0) // "1,235"
 *   formatNumber(null) // "-"
 */
export function formatNumber(value: number | null | undefined, maximumFractionDigits = DEFAULT_FRACTION_DIGITS): string {
  if (value === null || value === undefined || Number.isNaN(value)) return "-";
  return new Intl.NumberFormat("en", { maximumFractionDigits }).format(value);
}

/**
 * Format a number as an integer with commas
 *
 * @param value - The number to format
 * @returns the formatted number
 * @example
 *   formatInteger(1234.5678) // "1,235"
 *   formatInteger(null) // "-"
 */
export function formatInteger(value: number | null | undefined): string {
  return formatNumber(value, 0);
}

/**
 * The general form of our equation is:
 *   Y(x) = min + (max - min) / ((1 + e ^ (num_timesteps / 2) - x)
 *
 * We calculate values starting from zero.
 * num_timesteps/2 will center the sigmoid's growth around the middle time
 *
 * @param min is our left horizontal asymptote
 * @param max is our right horizontal asymptote
 * @param steps is the number of values to calculate
 * @param maximumFractionDigits optional param to apply rounding to a maximum digits
 */
export const sigmoid = (min: number, max: number, steps: number, maximumFractionDigits = DEFAULT_FRACTION_DIGITS): number[] => {
  if (steps <= 0) return [];

  const x = Array.from(Array(steps).keys());
  return x.map((subX) => roundFloat(min + (max - min) * (1 - 1 / (1 + (1 / (subX / (steps - 1)) - 1) ** -2)), maximumFractionDigits));
};

/**
 * Normalizes a value between 0 & 1.
 */
export const normalize = (val: number, min: number, max: number): number => {
  if (max - min === 0) return 0;
  return Math.min(Math.max((val - min) / (max - min), 0), 1);
};
