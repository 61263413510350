import { useState } from "react";
import { Fullscreen, FullscreenExit } from "@mui/icons-material";
import { Backdrop, Box, IconButton, Modal, Zoom } from "@mui/material";
import { defer } from "lodash";

import GridCard, { GridCardProps } from "./GridCard";

type ZoomableGridCardProps = GridCardProps & {
  /**
   * Should it start out zoomed
   */
  isZoomed?: boolean;
  children: JSX.Element;
  /**
   * The height of the modal in %, vh, or vw
   */
  zoomedHeight?: string;
  /**
   * The maximum height of the modal in %, vh, or vw
   */
  zoomedMaxHeight?: string;
  /**
   * The minimum height of the modal in %, vh, or vw
   */
  zoomedMinHeight?: string;
  /**
   * The width of the modal in %, vh, or vw
   */
  zoomedWidth?: string;
  /**
   * callback when the modal is opened
   */
  onOpen?: () => void;
  /**
   * callback when the modal is closed
   */
  onClose?: () => void;
};

/**
 * Allow a GridCard to be zoomed into a modal
 */
export const ZoomableGridCard = ({
  isZoomed,
  zoomedHeight = "60vw",
  zoomedMaxHeight = "80vh",
  zoomedMinHeight = "50vh",
  zoomedWidth = "90vw",
  onOpen,
  onClose,
  children,
  ...rest
}: ZoomableGridCardProps): JSX.Element => {
  const [zoomed, setZoomed] = useState(isZoomed ?? false);
  const [showZoomedChildren, setShowZoomedChildren] = useState(false);

  const toggleZoomed = () => {
    if (!zoomed && onOpen) {
      onOpen();
    }
    setZoomed((prev) => !prev);
  };

  const onEntered = () => {
    setShowZoomedChildren(zoomed);
  };

  const onExited = () => {
    setShowZoomedChildren(zoomed);
    // Only call onClose after the modal has exited and
    // defer to allow the modal to fully close and unmount the children
    defer(() => {
      if (onClose) {
        onClose();
      }
    });
  };

  return (
    <>
      <ZoomableGridCardContent toggleZoomed={toggleZoomed} {...rest}>
        {children}
      </ZoomableGridCardContent>
      <Modal open={zoomed} data-testid="zoomable-grid-card-modal" onClose={toggleZoomed} slots={{ backdrop: Backdrop }}>
        <Zoom in={zoomed} onEntered={onEntered} onExited={onExited}>
          <Box height="100%" display="flex" alignItems="center" justifyContent="center">
            <Box
              width={zoomedWidth}
              height={zoomedHeight}
              minHeight={zoomedMinHeight}
              maxHeight={zoomedMaxHeight}
              className="zoomable-grid-card-container"
              position="relative"
              bgcolor="#ffffff"
            >
              {showZoomedChildren && (
                <ZoomableGridCardContent zoomed toggleZoomed={toggleZoomed} {...rest} height="100%">
                  {children}
                </ZoomableGridCardContent>
              )}
            </Box>
          </Box>
        </Zoom>
      </Modal>
    </>
  );
};

type ZoomableGridCardContentProps = GridCardProps & {
  zoomed?: boolean;
  toggleZoomed: () => void;
};

const ZoomableGridCardContent = ({ zoomed, toggleZoomed, breakpoints, children, headerRightElement, ...rest }: ZoomableGridCardContentProps) => {
  // for zoomed state we always want to use the maximum number of columns
  const adjustedBreakpoints = !zoomed ? breakpoints : { ...Object.fromEntries(Object.entries(breakpoints).map(([key]) => [key, 12])) };
  return (
    <GridCard
      breakpoints={adjustedBreakpoints}
      headerRightElement={
        <Box display="flex" alignItems="center" gap={0.5}>
          {headerRightElement}
          <IconButton size="small" onClick={toggleZoomed} aria-label={zoomed ? "Close this zoomed panel" : "Zoom in on this panel"}>
            {zoomed ? <FullscreenExit fontSize="inherit" /> : <Fullscreen fontSize="inherit" />}
          </IconButton>
        </Box>
      }
      height={zoomed ? "100%" : undefined}
      {...rest}
    >
      {children}
    </GridCard>
  );
};
