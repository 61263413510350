import React from "react";
import { useField } from "react-final-form";
import { Alert, AlertTitle, Typography } from "@mui/material";

import { VersionError } from "../types";

const errorTypes: Record<VersionError, { title: string; description: React.ReactNode }> = {
  [VersionError.NO_DEMAND_MODEL]: {
    title: "Missing demand model",
    description: (
      <>
        To configure revenue, select a project version that has a demand model configured in its architecture, or follow these steps:
        <ol>
          <li>Go to the project version, navigate to the architecture section, and click on the demand tab.</li>
          <li>Create a demand model.</li>
          <li>Regenerate the design.</li>
          <li>Return to the report, and the revenue table will be populated.</li>
        </ol>
      </>
    ),
  },
  [VersionError.OUTDATED_PROJECT]: {
    title: "Outdated project",
    description: (
      <>
        This project is old and outdated, and it is not compatible with reports. However, if you still want to use it, follow these steps to update
        it:
        <ol>
          <li>Have write access to the selected old project.</li>
          <li>Make sure the architecture has configured the demand model.</li>
          <li>Add unit costs to the Bill of Materials (BOM).</li>
          <li>
            Optional but preferable:
            <ol type="a">
              <li>
                Add tags to the Bill of Materials (BOM) ruleset if applicable, e.g., material, labor, etc. The report cost breakdown chart will be
                more meaningful if tags are provided.
              </li>
            </ol>
          </li>
          <li>Regenerate the design and wait until it is complete.</li>
          <li>You can now use the project to generate a report.</li>
        </ol>
      </>
    ),
  },
  [VersionError.INCOMPLETE_DESIGN]: {
    title: "Incomplete design",
    description: "You need to generate a design before creating a report for this project and version.",
  },
  [VersionError.NO_BOM]: {
    title: "Undefined bill of materials (BOM)",
    description: "Report generation requires a defined Bill of Materials (BOM) with cost values.",
  },
  [VersionError.BOM_WITHOUT_COSTS]: {
    title: "No costs within bill of materials",
    description: "Report generation requires a defined Bill of Materials (BOM) with cost values.",
  },
};

const ReportDesignWarning: React.FC = () => {
  const {
    meta: { error },
  } = useField("Version");

  if (!Object.values(VersionError).includes(error)) return null;

  return (
    <Alert severity="warning" sx={{ mt: 4 }}>
      <AlertTitle>{errorTypes[error as VersionError].title}</AlertTitle>
      <Typography component="div">{errorTypes[error as VersionError].description}</Typography>
    </Alert>
  );
};

export default ReportDesignWarning;
