import { FullReport, MultiProject, Project, Report, ReportConfiguration, ResourceAllocation, RevenueConfiguration, Version } from "fond/types";

export type ReportWithConfiguration = Pick<FullReport, "Name" | "Description"> & {
  VersionID: string | null;
  MultiProjectID: string | null;
  ReportConfiguration: ReportConfiguration;
};

/**
 * The form fields for the revenue configuration also have a min/max take rate field
 * We use those values to calculate the spread of take rates for the reports planning horizon
 */
export type RevenueFormConfiguration = RevenueConfiguration & {
  TakeRateMin: number;
  TakeRateMax: number;
};

export type ResourceAllocationFormConfig = ResourceAllocation & {
  ExpansionRateCombinedValue: string;
};

export type ReportFormConfiguration = Partial<ReportConfiguration> &
  // All values within ReportConfiguration can be optional, but StepFrequency will always have a value
  Pick<ReportConfiguration, "StepFrequency" | "CostConfiguration"> & {
    Revenue?: { RevenueConfigurations: RevenueFormConfiguration[] };
    ResourceAllocation?: ResourceAllocationFormConfig;
  };

export interface ReportFormData extends Pick<Report, "Name" | "Description"> {
  Project: Project | MultiProject | null;
  Version: Version | null;
  ReportConfiguration: ReportFormConfiguration;
}

export interface ReportImportFormData extends Pick<Report, "Name" | "Description"> {
  Project?: Project | null;
  Version?: Version | null;
  CTSFiles: File[];
  CFOFiles: File[];
}

export enum VersionError {
  INCOMPLETE_DESIGN = "This version has no generated design",
  NO_DEMAND_MODEL = "This version has no demand model set",
  NO_BOM = "This version has no bill of materials set",
  BOM_WITHOUT_COSTS = "This version is using a bill of materials with no costs defined",
  OUTDATED_PROJECT = "This version is outdated",
}
