import React from "react";
import { Box } from "@mui/material";

import { TextField } from "fond/form/fields";
import { required } from "fond/utils/validation";

type ReportDetailsProps = {
  disabled: boolean;
};

export const ReportDetails: React.FC<ReportDetailsProps> = ({ disabled }) => {
  return (
    <>
      <Box maxWidth={300}>
        <TextField name="Name" required label="Report name" disabled={disabled} fullWidth validate={required} />
      </Box>
      <Box mt={2}>
        <TextField name="Description" label="Description" disabled={disabled} fullWidth multiline rows={3} />
      </Box>
    </>
  );
};
