import { Box, css, styled } from "@mui/material";

export const PaperRightIconWrapper = styled(Box)(
  ({ theme }) => css`
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: ${theme.palette.secondary.main};
    color: ${theme.palette.common.white};

    & > svg {
      height: 20px;
    }
  `
);

export const MapContainer = styled(Box)(
  ({ theme }) => `
      position: relative;
      border-radius: ${theme.shape.borderRadius}px;
    `
);
