import { useEffect } from "react";
import { skipToken } from "@reduxjs/toolkit/query";
import { isEqual } from "lodash";

import { multiProjectsSlice, useGetMultiProjectStatisticsQuery } from "fond/api";
import { MultiProject } from "fond/types";
import { useAppDispatch } from "fond/utils/hooks";
import { normalize } from "fond/utils/number";

import { calculateScore } from "./helper";

/**
 * Hook that returns a MultiProjectArea collection that includes the client side
 * calculated ranking score.
 */
export const useUpdateSubareaScores = async (multiProject?: MultiProject): Promise<void> => {
  const dispatch = useAppDispatch();
  const { data } = useGetMultiProjectStatisticsQuery(multiProject?.ID ?? skipToken);

  useEffect(() => {
    if (multiProject?.ID && data) {
      let min = 100;
      let max = 0;

      let areas =
        multiProject?.Areas.map((area) => {
          let score = -1;
          if (data?.Areas) {
            score = calculateScore(multiProject.AssessConfiguration, area.ID, data.Areas);
          }

          min = Math.min(min, score);
          max = Math.max(max, score);

          return {
            ...area,
            Score: score,
          };
        }) || [];

      // Normalize values
      areas = areas.map(({ Score, ...rest }) => ({
        ...rest,
        Score: normalize(Score, min, max) * 100,
      }));

      // If area scores have changed we update the local cache
      if (!isEqual(areas, multiProject.Areas)) {
        dispatch(
          multiProjectsSlice.util.updateQueryData("getMultiProject", multiProject.ID, (draft) => {
            draft.Areas = areas;
          })
        );
      }
    }
  }, [data, dispatch, multiProject]);
};
