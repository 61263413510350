import React, { useEffect, useState } from "react";
import { Cancel } from "@mui/icons-material";
import { Box } from "@mui/material";

import { Progress, Step } from "ui";

import { StatusTypes } from "fond/project/redux";
import { Status, WorkflowStep } from "fond/types";

const stepLabels: Record<WorkflowStep["ID"], string> = {
  Preprocessor: "Analyzing the problem",
  Tier_1_Solve: "Determining drop hub locations",
  Tier_2_Solve: "Determining distribution hub locations",
  Tier_3_Solve: "Connecting to feeder hubs",
  FlexNapPostprocessor: "Processing FlexNAPs",
  BsOutput: "Generating output layers",
  Splicing: "Creating splice tables",
  BOM: "Counting materials and costs",
  CostToServe: "Calculating cost to serve",
};

const statusMap: Record<Status, Step["Status"]> = {
  Cancelled: "Error",
  Complete: "Success",
  Cancelling: "Cancelling",
  Idle: "Idle",
  Running: "InProgress",
  Terminated: "Error",
  NotReady: "Idle",
};

interface IProps {
  workflow: WorkflowStep[];
}

const SolveProgress: React.FC<IProps> = ({ workflow }: IProps) => {
  const [stepDisplays, setStepDisplays] = useState<Step[]>([]);

  useEffect(() => {
    const display: Step[] = [];
    if (workflow) {
      workflow.some((step, index) => {
        let status: Step["Status"] = statusMap[step.Status];
        if (step.Status === StatusTypes.Idle) {
          if (index === 0 || workflow[index - 1].Status === StatusTypes.Complete) {
            status = statusMap[StatusTypes.Running as Status];
          }
        }

        display.push({
          ID: step.ID,
          Label: stepLabels[step.ID],
          Status: status,
          Icon: step.Status === StatusTypes.Cancelled ? <Cancel /> : undefined,
        });

        return step.Status === StatusTypes.Terminated;
      });
    }
    setStepDisplays(display);
  }, [workflow]);

  return (
    <Box data-testid="solve-progress-container">
      <Progress steps={stepDisplays} />
    </Box>
  );
};

SolveProgress.displayName = "SolveProgress";
export default SolveProgress;
