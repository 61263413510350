import { Box, InputAdornment } from "@mui/material";

import DatePickerField from "fond/form/fields/DatePickerField";
import { NumericField } from "fond/form/fields/NumericField";
import SelectField from "fond/form/fields/SelectField";
import { parseFieldAsNumber } from "fond/form/util";
import { required } from "fond/utils/validation";

type ReportGeneralSettingsProps = {
  disabled: boolean;
};

export const ReportGeneralSettings: React.FC<ReportGeneralSettingsProps> = ({ disabled }: ReportGeneralSettingsProps) => {
  return (
    <Box mt={2} display="flex" columnGap={4}>
      <Box flex={1} display="flex" alignItems="baseline">
        <Box flex={1} mr={1.5}>
          <NumericField
            name="ReportConfiguration.PlanningHorizon"
            min={1}
            max={24}
            required
            label="Period"
            parse={parseFieldAsNumber}
            fullWidth
            validate={required}
            disabled={disabled}
          />
        </Box>
        <Box flex={1}>
          <SelectField
            name="ReportConfiguration.StepFrequency"
            size="small"
            label="Frequency"
            fullWidth
            disabled={disabled}
            options={[
              { value: "monthly", displayValue: "Months" },
              { value: "quarterly", displayValue: "Quarters" },
              { value: "yearly", displayValue: "Years" },
            ]}
            sxProps={{ label: { opacity: 0 } }}
          />
        </Box>
      </Box>
      <Box flex={1}>
        <DatePickerField name="ReportConfiguration.StartTime" label="Start date" required size="small" format="DD MMM YYYY" disabled={disabled} />
      </Box>
      <Box flex={1}>
        <NumericField
          name="ReportConfiguration.DiscountRate"
          required
          label="Discount rate"
          fullWidth
          min={0}
          max={100}
          parse={parseFieldAsNumber}
          InputProps={{ endAdornment: <InputAdornment position="end">% p.a</InputAdornment> }}
          disabled={disabled}
        />
      </Box>
    </Box>
  );
};
