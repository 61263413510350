import React, { useCallback, useMemo, useRef } from "react";
import { SaveAlt } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { lightBlue, pink, teal } from "@mui/material/colors";
import {
  AgChartClickEvent,
  AgChartOptions,
  AgCharts,
  AgDonutSeriesOptions,
  AgDonutSeriesTooltipRendererParams,
  AgNodeClickEvent,
  AgTooltipRendererResult,
} from "ag-charts-community";
import { AgChartsReact } from "ag-charts-react";
import chroma from "chroma-js";
import { chain, sumBy } from "lodash";

import mixpanel from "fond/mixpanel";
import { CostBreakdown } from "fond/types";
import { formatCurrency } from "fond/utils/currency";
import { formatFractionPercent } from "fond/utils/number";
import { ZoomableGridCard } from "fond/widgets/ZoomableGridCard";

import ReportChartNavigation, { NavigationItem } from "../ReportChartNavigation";

import { Container } from "./ReportCostChart.styles";

export const MAX_DRILL_DEPTH = 2;
const CHART_TEXT_FONT_FAMILY = "Roboto, Helvetica, Arial, sans-serif";

/**
 * Colours used for each drilldown level. We define a min & max color and
 * use chroma.scale() to generate the color ranges for each depth based on
 * the number of segments required within the donut
 */
const drillDownColors = [
  [lightBlue[200], lightBlue[900]],
  [teal[200], teal[900]],
  [pink[100], pink[900]],
];

const drillDownColumns = [
  ["Tags", "TotalCost"],
  ["GroupID", "TotalCost"],
  ["RuleID", "TotalCost"],
];

interface IProps {
  data?: Omit<CostBreakdown, "CostBins">[];
  depth: number;
  onDrillDown(params: AgNodeClickEvent<unknown>): void;
  onDrillUp(params: AgChartClickEvent): void;
  totalCost: number;
  navigation: NavigationItem[];
  navigateBackTo(value: number): void;
}

export const groupBy = (key: string, data?: Omit<CostBreakdown, "CostBins">[]): Array<{ TotalCost: CostBreakdown["TotalCost"]; Key: string }> => {
  return (
    chain(data)
      // Filter rows that have cost bin ranges other than the active bins
      .groupBy(key)
      // Aggregate the TotalCost and Counts for rows that have the same RuleID
      .map((values, id) => ({
        TotalCost: sumBy(values, "TotalCost"),
        ColumnID: key,
        Key: id,
      }))
      .value()
  );
};

const ReportCostChart: React.FC<IProps> = ({ data, depth, onDrillDown, onDrillUp, totalCost, navigation, navigateBackTo }) => {
  const chartRef = useRef<AgChartsReact | null>(null);
  const chartData = useMemo(() => groupBy(drillDownColumns[depth][0], data), [data, depth]);
  const previousChartRef = useRef<AgChartsReact | null>(null);

  const getTooltip = useCallback(
    (params: AgDonutSeriesTooltipRendererParams): AgTooltipRendererResult => {
      const total = formatCurrency(params.datum.TotalCost, { notation: "compact" });
      const percentage = formatFractionPercent(params.datum.TotalCost / totalCost);
      return {
        title: "Total cost",
        content: `${params.datum.Key}: ${total} (${percentage})`,
      };
    },
    [totalCost]
  );

  const chartSeries: AgDonutSeriesOptions[] = useMemo(
    (): AgDonutSeriesOptions[] => [
      {
        angleKey: "TotalCost",
        calloutLabel: { enabled: false },
        fills: chroma.scale(drillDownColors[depth]).colors(chartData?.length),
        innerRadiusRatio: 0.6,
        listeners: {
          nodeClick: onDrillDown,
        },
        sectorLabel: { enabled: false },
        sectorLabelKey: "Key",
        type: "donut",
        tooltip: {
          renderer: getTooltip,
        },
      },
    ],
    [chartData, depth, getTooltip, onDrillDown]
  );

  const options: AgChartOptions = useMemo(
    () => ({
      data: chartData,
      series: chartSeries,
      listeners: {
        click: onDrillUp,
      },
      subtitle: {
        text: "Click a category to view more details",
        enabled: depth < MAX_DRILL_DEPTH,
        fontSize: 12,
        fontFamily: CHART_TEXT_FONT_FAMILY,
      },
      title: {
        enabled: true,
        text: formatCurrency(sumBy(chartData, "TotalCost"), { notation: "compact" }), // TODO
        fontSize: 18,
        fontWeight: 700,
        fontFamily: CHART_TEXT_FONT_FAMILY,
      },
    }),
    [chartData, chartSeries, depth, onDrillUp]
  );

  const downloadChart = () => {
    AgCharts.download(chartRef.current!.chart);
    mixpanel.track("Report", "Cost", "Downloaded cost chart");
  };

  const onOpenZoom = () => {
    // store the current chart
    previousChartRef.current = chartRef.current;
  };

  const onCloseZoom = useCallback(() => {
    // restore the previous chart
    chartRef.current = previousChartRef.current;
  }, [previousChartRef]);

  return (
    <ZoomableGridCard
      breakpoints={{ lg: 6, xs: 12 }}
      title="Cost breakdown"
      headerRightElement={
        <IconButton data-testid="download-cost-button" size="small" onClick={downloadChart}>
          <SaveAlt fontSize="inherit" />
        </IconButton>
      }
      onOpen={onOpenZoom}
      onClose={onCloseZoom}
    >
      <>
        <ReportChartNavigation navigation={navigation} navigateBackTo={navigateBackTo} />
        <Box flex={1}>
          <Container>
            <AgChartsReact ref={chartRef} options={options} />
          </Container>
        </Box>
      </>
    </ZoomableGridCard>
  );
};

export default ReportCostChart;
