import React, { useEffect, useMemo } from "react";
import { Box, Slider, Typography } from "@mui/material";

import { Report, SystemOfMeasurement } from "fond/types";
import { FullMultiReport, MultiReportCategory } from "fond/types/multiReport";
import { convertFeetToMeters } from "fond/utils";
import { formatFractionPercent, formatNumber } from "fond/utils/number";

interface IProps {
  multiReport: FullMultiReport;
  reports: Report[];
  category: MultiReportCategory;
  systemOfMeaurement?: SystemOfMeasurement;
  onSliderChange(values: number[]): void;
}

const CityReportMapSlider: React.FC<IProps> = ({ multiReport, reports, category, systemOfMeaurement, onSliderChange }) => {
  const [slideRange, setSlideRange] = React.useState<number[] | null>(null);

  const valueList = useMemo(() => {
    return [...reports]
      .filter((report): report is Report & { [key in typeof category]: number } => report[category] !== null)
      .map((report) => report[category]);
  }, [reports, category]);

  useEffect(() => {
    setSlideRange(null);
  }, [multiReport, category]);

  if (valueList.length < 1) return null;

  const uniqueValues = [...new Set(valueList)].sort((a, b) => a - b);

  const formatLabel = (value: number): string => {
    if (["Irr", "Roi"].includes(category)) {
      return formatFractionPercent(value, 0);
    }
    if (category === "CostPerMeter" && systemOfMeaurement === "imperial") {
      return formatNumber(convertFeetToMeters(value));
    }
    return formatNumber(value, 0);
  };

  const sliderMarks = uniqueValues.map((value, index, list) => ({
    value: index,
    label: [0, list.length - 1].includes(index) ? formatLabel(value) : undefined,
  }));

  const onChange = (_: Event, value: number | number[]) => {
    const newValue = value as number[];
    setSlideRange(newValue);
    onSliderChange(uniqueValues.slice(newValue[0], newValue[1] + 1));
  };

  return (
    <Box>
      <Typography>Range</Typography>
      <Box px={1}>
        <Slider
          min={0}
          max={uniqueValues.length - 1}
          track="normal"
          marks={sliderMarks}
          step={null}
          valueLabelDisplay="auto"
          valueLabelFormat={(value) => formatLabel(uniqueValues[value])}
          value={slideRange || [0, uniqueValues.length - 1]}
          onChange={onChange}
        />
      </Box>
    </Box>
  );
};

export default CityReportMapSlider;
