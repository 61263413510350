import React from "react";
import { GridOptions } from "@ag-grid-community/core";

import { MultiProject } from "fond/types";
import { AgGrid } from "fond/widgets";

import { rankingColumns } from "../AssessPanel/rankingColumns";

const gridOptions: GridOptions = {
  animateRows: true,
  rowGroupPanelShow: "never",
  sideBar: false,
  pagination: false,
  suppressMovableColumns: true,
  domLayout: "normal",
};

interface SubareaListProps {
  multiProject: MultiProject;
}

const SubareaList: React.FC<SubareaListProps> = ({ multiProject }: SubareaListProps) => {
  return (
    <AgGrid
      columnDefs={rankingColumns}
      rowData={multiProject.Areas}
      containerProps={{
        height: 158,
      }}
      size="compact"
      variant="outlined"
      gridOptions={gridOptions}
    />
  );
};

export default SubareaList;
