import React, { useContext, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Edit } from "@mui/icons-material";
import { Box, Tooltip } from "@mui/material";

import { selectAllVersionLayerConfigsInOrder, selectNonExcludedVersionStyles, useGetMultiProjectQuery } from "fond/api";
import { Map } from "fond/cityPlanner";
import SearchField from "fond/map/Field/SearchField";
import MapButtons from "fond/map/MapButtons";
import { MapContext } from "fond/map/MapProvider";
import MapStyleToggle from "fond/map/MapStyleToggle";
import { Toolbar } from "fond/map/Toolbar";
import { getCurrentProjectView } from "fond/project";
import { EditMode } from "fond/project/redux";
import { Store, View } from "fond/types";
import { isValidBoundingBox } from "fond/utils";

import { ITabProps } from "../factory";

interface RouteParams {
  cityId: string;
}

/**
 * Main widget component that determines the content rendered within the tab
 */
export const Widget: React.FC<ITabProps> = ({ node }) => {
  const { cityId } = useParams<keyof RouteParams>();
  const { data: project } = useGetMultiProjectQuery(cityId as string, { skip: !cityId });
  const { map } = useContext(MapContext);
  const layerConfigs = useSelector((state: Store) => selectAllVersionLayerConfigsInOrder(state, cityId));
  const styles = useSelector((state: Store) => selectNonExcludedVersionStyles(state, cityId));
  const { layers: layerView } = useSelector((state: Store): View => getCurrentProjectView(state.project));
  const { project: projectData } = useSelector((state: Store) => state.project.projects[cityId!]);
  const editMode = useSelector((state: Store) => state.project.editMode);

  const shouldShowToolbar = useMemo(() => {
    return editMode !== EditMode.measure && editMode !== EditMode.none;
  }, [editMode]);

  const onZoomToDesignHandler = () => {
    if (projectData?.BoundingBox && isValidBoundingBox(projectData.BoundingBox)) {
      map?.fitBounds(projectData.BoundingBox, { padding: 20 });
    }
  };

  useEffect(() => {
    node.setEventListener("resize", () => {
      setTimeout(() => {
        map?.resize();
      }, 0);
    });

    node.setEventListener("visibility", () => {
      setTimeout(() => {
        map?.resize();
      }, 0);
    });

    return () => {
      node.removeEventListener("resize");
      node.removeEventListener("visibility");
    };
  }, [map, node]);

  if (!project) return null;

  return (
    <Map layerView={layerView} layerConfigs={layerConfigs} styles={styles}>
      {shouldShowToolbar && <Toolbar />}
      <Box className="left-sidebar-section">
        <MapStyleToggle />
      </Box>
      <Box className="right-sidebar-section">
        <Box display="flex" overflow="hidden" pb={1} flexGrow={1}>
          <SearchField />
        </Box>
        <MapButtons onZoomToDesign={onZoomToDesignHandler} />
      </Box>
    </Map>
  );
};

/**
 * Icon component used by the iconFactory to generate the tabs left icon adornment.
 */
export const Icon: React.FC<ITabProps> = () => (
  <Tooltip title="FOND Designer" arrow placement="right">
    <Edit />
  </Tooltip>
);
