import React, { memo } from "react";
import { ICellRendererParams } from "@ag-grid-community/core";

import { MultiProjectAreaImportStatus, ReportStatus } from "fond/types";

import { CellWrapper } from "./rowMenuRenderer.styles";
import { Chip } from "./StatusCellRenderer.styles";

type StatusEntry = {
  text: string;
  icon: JSX.Element;
} | null;

interface IProps extends ICellRendererParams {
  status: ReportStatus | MultiProjectAreaImportStatus;
  statusEntries: {
    [key in ReportStatus["State"] | MultiProjectAreaImportStatus["State"]]: StatusEntry;
  };
}

const StatusCellRenderer: React.FC<IProps> = memo(({ status, statusEntries }: IProps) => {
  if (!status) {
    return null;
  }
  const spec = statusEntries[status.State];
  if (!spec) {
    return null;
  }

  return (
    <CellWrapper>
      <Chip label={spec.text} icon={spec.icon} size="small" className={`status-${status.State}`} />
    </CellWrapper>
  );
});

StatusCellRenderer.displayName = "StatusCellRenderer";

export default StatusCellRenderer;
