import { Link } from "react-router-dom";
import { AccountCircle } from "@mui/icons-material";
import { Alert, Box, Button, IconButton as BaseIconButton, Menu as BaseMenu, styled } from "@mui/material";

import { USERMANAGEMENT_BANNER_HEIGHT } from "fond/widgets/PageContent/constants";

export const MainContainer = styled(Box)`
  position: sticky;
  top: 0;
  z-index: 10;
`;

export const Topbar = styled(Box)(({ theme }) => ({
  height: 52,
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: theme.palette.biarri.primary.midnight,
  color: "white",
  padding: theme.spacing(1),
  paddingLeft: 0,
  fontSize: theme.typography.h5.fontSize,
}));

export const Menu = styled(BaseMenu)(
  ({ theme }) => `
    & .MuiMenu-list {
      width: 198px;
      padding: ${theme.spacing(0.5)}px 0;
    }
  `
);

export const AccountIcon = styled(AccountCircle)(({ theme }) => ({
  fill: theme.palette.biarri.primary.lightGrey,
}));

export const Banner = styled(Alert)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  zIndex: 10,
  paddingRight: theme.spacing(2),
  height: USERMANAGEMENT_BANNER_HEIGHT,
}));

export const ManageAccountButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1),
  whiteSpace: "nowrap",
}));

export const IconButton = styled(BaseIconButton)(({ theme }) => ({
  margin: `0 ${theme.spacing(0.5)}`,
  "&:hover": {
    backgroundColor: theme.palette.background.topbar.hover,
  },
  "& > svg": {
    color: theme.palette.common.white,
  },
}));

export const IconRouterLink = styled(Link)(({ theme }) => ({
  margin: `0 ${theme.spacing(0.5)}`,
  display: "inline-flex",
  fontSize: "1.125rem",
  minWidth: "auto",
  padding: "5px",

  "&:hover": {
    backgroundColor: theme.palette.background.topbar.hover,
  },
  "& > svg": {
    color: theme.palette.common.white,
  },
}));
