import { RefObject, useEffect, useMemo, useRef } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";

import {
  ACCOUNT_KEY,
  FOLDER_ID,
  INVITATION_ID_KEY,
  INVITATION_TOKEN_KEY,
  MULTI_PROJECT_ID,
  MULTI_REPORT_FOLDER_ID,
  PRODUCT_CATEGORY_KEY,
  PRODUCT_SELECTION_KEY,
  PURCHASE_TYPE_KEY,
  RETURN_URL,
  SEARCH_KEY,
  VERSION_ID,
} from "fond/constants";
import { AnyObject, AppThunkDispatch, Store } from "fond/types";

// Unobserve if we are already observing old element
const unobserve = (observer: any, current: any) => {
  if (observer?.current?.unobserve && current) {
    observer.current.unobserve(current);
  }
};

const observe = (observer: any, current: any) => {
  if (observer?.current?.observe && current) {
    observer.current.observe(current);
  }
};

/**
 * A hook that implements an Intersection Observer on the element passed to it.
 * This allows components to monitor for when an element comes into the users view.
 *
 * @param ref React reference to the Element to observe
 * @param options observer options - see {@link https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API}
 * @param monitor the dependency list to monitor for changes
 */
export const useOnScreen = (
  ref: RefObject<HTMLElement>,
  callback: (entry: IntersectionObserverEntry) => void,
  options?: Partial<{ rootMargin: string; root: Element; threshold: number }>,
  monitor: React.DependencyList = []
): void => {
  // State and setter for storing whether element is visible
  const current = ref && ref.current;
  const observer = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        callback(entry);
      },
      {
        rootMargin: "0px",
        threshold: 0,
        root: null,
        ...options,
      }
    );
    observe(observer, current);

    return () => {
      unobserve(observer, current);
    };
  }, [current, [...monitor]]);
};

/**
 * A hook that implements the Resize Observer on the element passed to it.
 * This will allow components to monitor for when an elements dimensions change.
 *
 * @param ref React reference to the Element to observe
 * @param callback The ResizeObserverCallback function to be be fired when a resize is detected
 */
export const useResizeObserver = (ref: RefObject<Element>, callback: ResizeObserverCallback): void => {
  const current = ref && ref.current;
  const observer = useRef<ResizeObserver | null>(null);

  useEffect(() => {
    observer.current = new ResizeObserver(callback);
    observe(observer, current);

    return () => {
      unobserve(observer, current);
    };
  }, [current]);
};

/**
 * This is a helper hook for helping understand why a component
 * re-renders.  By passing props to this hook any changes will
 * be outputted to console.log()
 *
 * Example
 * useTraceUpdate(props)
 * useTraceUpdate({ prop1, prop2 })
 */
export const useTraceUpdate = (props: AnyObject, message = "Change in values has occurred:"): void => {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps: AnyObject, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      // eslint-disable-next-line no-console
      console.log(message, changedProps);
    }
    prev.current = props;
  });
};

/**
 * Pre-defined dispatch hook, which helps:
 * > For useSelector, it saves you the need to type (state: RootState) every time
 * > For useDispatch, the default Dispatch type does not know about thunks or other middleware.
 *   In order to correctly dispatch thunks, you need to use the specific customized AppDispatch type
 *   from the store that includes the thunk middleware types, and use that with useDispatch.
 *   Adding a pre-typed useDispatch hook keeps you from forgetting to import AppDispatch where it's needed.
 *
 * See: https://redux.js.org/usage/usage-with-typescript
 */
export const useAppDispatch = () => useDispatch<AppThunkDispatch>();
export const useAppSelector: TypedUseSelectorHook<Store> = useSelector;

type QueryParameters =
  | typeof SEARCH_KEY
  | typeof ACCOUNT_KEY
  | typeof FOLDER_ID
  | typeof VERSION_ID
  | typeof MULTI_PROJECT_ID
  | typeof MULTI_REPORT_FOLDER_ID
  | typeof RETURN_URL
  | typeof INVITATION_ID_KEY
  | typeof INVITATION_TOKEN_KEY
  | typeof PRODUCT_SELECTION_KEY
  | typeof PRODUCT_CATEGORY_KEY
  | typeof PURCHASE_TYPE_KEY;

/**
 * Hook to get query parameter key
 * @param key query parameter key to query
 * @returns value attached to the query parameter, null if not found
 * @link https://v5.reactrouter.com/web/example/query-parameters
 */
export const useQueryParams = <T extends string = string>(key: QueryParameters): T | null => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search).get(key) as T, [key, search]);
};
