import React from "react";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import { Box, Typography } from "@mui/material";

import { NpvGreen, NpvRed } from "fond/cityPlanner/configuration/cityReportConfiguration";

const NpvLegend: React.FC = () => {
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row" alignItems="center">
        <SquareRoundedIcon fontSize="small" htmlColor={NpvRed} />
        <Typography ml={1}>NPV &lt; 0</Typography>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        <SquareRoundedIcon fontSize="small" htmlColor={NpvGreen} />
        <Typography ml={1}>NPV &gt; 0</Typography>
      </Box>
    </Box>
  );
};

export default NpvLegend;
